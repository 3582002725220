import React from 'react';
import { Code, Binary, Terminal, GitBranch } from 'lucide-react';
import PageTitle from '../../components/PageTitle';

const QuantumSoftware = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <PageTitle 
        title="Quantum Software Development"
        description="Comprehensive guide to quantum software development tools and frameworks"
        keywords="quantum software, Qiskit, Q#, Cirq, quantum programming"
      />

      <h1 className="text-3xl font-bold text-[#232323] mb-6">Quantum Software Development</h1>

      <div className="prose prose-lg max-w-none">
        <div className="bg-white rounded-lg shadow-md p-6 mb-8">
          <h2 className="flex items-center text-2xl font-semibold mb-4">
            <Code className="w-6 h-6 text-[#EC6224] mr-2" />
            Quantum Programming Languages
          </h2>
          
          <h3 className="text-xl font-semibold mt-6 mb-3">Qiskit (Python)</h3>
          <pre className="bg-gray-900 text-white p-4 rounded-lg overflow-x-auto my-4">
            {`from qiskit import QuantumCircuit, execute, Aer

# Create a quantum circuit
qc = QuantumCircuit(2, 2)

# Create Bell state
qc.h(0)  # Hadamard gate on qubit 0
qc.cx(0, 1)  # CNOT with control=0, target=1

# Measure qubits
qc.measure([0,1], [0,1])

# Execute circuit on simulator
backend = Aer.get_backend('qasm_simulator')
job = execute(qc, backend, shots=1000)
result = job.result()
counts = result.get_counts(qc)
print(counts)`}
          </pre>

          <h3 className="text-xl font-semibold mt-6 mb-3">Q# (Microsoft)</h3>
          <pre className="bg-gray-900 text-white p-4 rounded-lg overflow-x-auto my-4">
            {`namespace QuantumRNG {
    open Microsoft.Quantum.Canon;
    open Microsoft.Quantum.Intrinsic;
    
    operation GenerateRandomBit() : Result {
        // Allocate a qubit
        use q = Qubit();
        
        // Put the qubit in superposition
        H(q);
        
        // Measure the qubit
        return M(q);
    }
}`}
          </pre>
        </div>

        <div className="bg-white rounded-lg shadow-md p-6 mb-8">
          <h2 className="flex items-center text-2xl font-semibold mb-4">
            <Terminal className="w-6 h-6 text-[#EC6224] mr-2" />
            Development Tools
          </h2>

          <h3 className="text-xl font-semibold mt-6 mb-3">Circuit Visualization</h3>
          <pre className="bg-gray-900 text-white p-4 rounded-lg overflow-x-auto my-4">
            {`# Qiskit circuit visualization
from qiskit.visualization import circuit_drawer

def create_ghz_state(n_qubits):
    circuit = QuantumCircuit(n_qubits, n_qubits)
    circuit.h(0)
    for i in range(n_qubits-1):
        circuit.cx(i, i+1)
    circuit.measure_all()
    return circuit

# Create and display a GHZ state circuit
ghz_circuit = create_ghz_state(3)
circuit_drawer(ghz_circuit, output='mpl')`}
          </pre>

          <h3 className="text-xl font-semibold mt-6 mb-3">Quantum Debug Tools</h3>
          <pre className="bg-gray-900 text-white p-4 rounded-lg overflow-x-auto my-4">
            {`# State vector simulation
from qiskit.quantum_info import Statevector

# Create a simple circuit
qc = QuantumCircuit(2)
qc.h(0)
qc.cx(0, 1)

# Get the statevector
state = Statevector.from_instruction(qc)
print(state)`}
          </pre>
        </div>

        <div className="bg-white rounded-lg shadow-md p-6 mb-8">
          <h2 className="flex items-center text-2xl font-semibold mb-4">
            <Binary className="w-6 h-6 text-[#EC6224] mr-2" />
            Quantum Simulators
          </h2>

          <h3 className="text-xl font-semibold mt-6 mb-3">State Vector Simulation</h3>
          <pre className="bg-gray-900 text-white p-4 rounded-lg overflow-x-auto my-4">
            {`# Using different Qiskit simulators
from qiskit import Aer, execute
from qiskit.providers.aer import QasmSimulator

# Create a quantum circuit
qc = QuantumCircuit(3, 3)
qc.h(0)
qc.cx(0, 1)
qc.cx(1, 2)
qc.measure_all()

# Statevector simulator
sv_backend = Aer.get_backend('statevector_simulator')
sv_result = execute(qc, sv_backend).result()
statevector = sv_result.get_statevector()

# QASM simulator with noise model
noise_model = NoiseModel()
noise_backend = QasmSimulator()
noise_result = execute(qc, 
                      noise_backend,
                      noise_model=noise_model,
                      shots=1000).result()
counts = noise_result.get_counts()`}
          </pre>
        </div>

        <div className="bg-white rounded-lg shadow-md p-6">
          <h2 className="flex items-center text-2xl font-semibold mb-4">
            <GitBranch className="w-6 h-6 text-[#EC6224] mr-2" />
            Version Control and CI/CD
          </h2>

          <h3 className="text-xl font-semibold mt-6 mb-3">Testing Quantum Code</h3>
          <pre className="bg-gray-900 text-white p-4 rounded-lg overflow-x-auto my-4">
            {`# pytest example for quantum circuit
import pytest
from qiskit import QuantumCircuit, Aer, execute

def test_bell_state():
    # Create Bell state
    qc = QuantumCircuit(2, 2)
    qc.h(0)
    qc.cx(0, 1)
    qc.measure_all()
    
    # Execute and get counts
    backend = Aer.get_backend('qasm_simulator')
    counts = execute(qc, backend, shots=1000).result().get_counts()
    
    # Check if results are approximately balanced
    assert abs(counts.get('00', 0) - counts.get('11', 0)) < 100`}
          </pre>

          <div className="mt-8 p-4 bg-blue-50 rounded-lg">
            <h4 className="text-lg font-semibold mb-2">Additional Resources</h4>
            <ul className="list-disc list-inside space-y-2">
              <li>
                <a 
                  href="https://qiskit.org/documentation/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#EC6224] hover:text-[#232323]"
                >
                  Qiskit Documentation
                </a>
              </li>
              <li>
                <a 
                  href="https://docs.microsoft.com/quantum/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#EC6224] hover:text-[#232323]"
                >
                  Microsoft Q# Documentation
                </a>
              </li>
              <li>
                <a 
                  href="https://quantumai.google/cirq"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#EC6224] hover:text-[#232323]"
                >
                  Google Cirq Documentation
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuantumSoftware;