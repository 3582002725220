import React from 'react';
import { Shield, Lock, FileKey, Settings } from 'lucide-react';
import PageTitle from '../../components/PageTitle';

const SELinux = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <PageTitle 
        title="SELinux Configuration"
        description="Understanding and implementing SELinux for enhanced system security"
        keywords="SELinux, security, Linux security, MAC"
      />

      <h1 className="text-3xl font-bold text-[#232323] mb-6">SELinux Configuration Guide</h1>

      <div className="prose prose-lg max-w-none">
        <div className="bg-white rounded-lg shadow-md p-6 mb-8">
          <h2 className="flex items-center text-2xl font-semibold mb-4">
            <Shield className="w-6 h-6 text-[#EC6224] mr-2" />
            SELinux Basics
          </h2>
          
          <h3 className="text-xl font-semibold mt-6 mb-3">Modes of Operation</h3>
          <pre className="bg-gray-900 text-white p-4 rounded-lg overflow-x-auto my-4">
            {`# Check SELinux status
getenforce

# Set SELinux mode
setenforce 1  # Enforcing
setenforce 0  # Permissive

# Configure in /etc/selinux/config
SELINUX=enforcing
SELINUXTYPE=targeted`}
          </pre>

          <h3 className="text-xl font-semibold mt-6 mb-3">Context Management</h3>
          <pre className="bg-gray-900 text-white p-4 rounded-lg overflow-x-auto my-4">
            {`# View file context
ls -Z /path/to/file

# Set file context
chcon -t httpd_sys_content_t /var/www/html/index.html

# Restore default context
restorecon -R -v /var/www/html`}
          </pre>
        </div>

        <div className="bg-white rounded-lg shadow-md p-6 mb-8">
          <h2 className="flex items-center text-2xl font-semibold mb-4">
            <Lock className="w-6 h-6 text-[#EC6224] mr-2" />
            Policy Configuration
          </h2>

          <h3 className="text-xl font-semibold mt-6 mb-3">Managing Policies</h3>
          <pre className="bg-gray-900 text-white p-4 rounded-lg overflow-x-auto my-4">
            {`# List available policy modules
semodule -l

# Enable/disable module
semodule -e httpd_module
semodule -d httpd_module

# Install new policy module
semodule -i mymodule.pp`}
          </pre>
        </div>

        <div className="bg-white rounded-lg shadow-md p-6">
          <h2 className="flex items-center text-2xl font-semibold mb-4">
            <Settings className="w-6 h-6 text-[#EC6224] mr-2" />
            Troubleshooting
          </h2>

          <h3 className="text-xl font-semibold mt-6 mb-3">Common Issues</h3>
          <pre className="bg-gray-900 text-white p-4 rounded-lg overflow-x-auto my-4">
            {`# View SELinux messages
ausearch -m AVC

# Generate policy module from logs
audit2allow -a -M mymodule

# View boolean settings
getsebool -a

# Set boolean value
setsebool -P httpd_can_network_connect on`}
          </pre>

          <div className="mt-8 p-4 bg-blue-50 rounded-lg">
            <h4 className="text-lg font-semibold mb-2">Additional Resources</h4>
            <ul className="list-disc list-inside space-y-2">
              <li>
                <a 
                  href="https://access.redhat.com/documentation/en-us/red_hat_enterprise_linux/8/html/using_selinux/index"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#EC6224] hover:text-[#232323]"
                >
                  Red Hat SELinux Guide
                </a>
              </li>
              <li>
                <a 
                  href="https://wiki.centos.org/HowTos/SELinux"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#EC6224] hover:text-[#232323]"
                >
                  CentOS SELinux Guide
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SELinux;