import React from 'react';
import { Code, Terminal, GitBranch, Database } from 'lucide-react';
import PageTitle from '../../components/PageTitle';

const SystemProgramming = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <PageTitle 
        title="Linux System Programming"
        description="Guide to system calls, low-level programming, and Linux system development"
        keywords="system programming, Linux, system calls, C programming"
      />

      <h1 className="text-3xl font-bold text-[#232323] mb-6">Linux System Programming Guide</h1>

      <div className="prose prose-lg max-w-none">
        <div className="bg-white rounded-lg shadow-md p-6 mb-8">
          <h2 className="flex items-center text-2xl font-semibold mb-4">
            <Code className="w-6 h-6 text-[#EC6224] mr-2" />
            System Calls
          </h2>
          
          <h3 className="text-xl font-semibold mt-6 mb-3">File Operations</h3>
          <pre className="bg-gray-900 text-white p-4 rounded-lg overflow-x-auto my-4">
            {`#include <fcntl.h>
#include <unistd.h>

int main() {
    int fd = open("file.txt", O_RDWR | O_CREAT, 0644);
    if (fd == -1) {
        perror("open");
        return 1;
    }

    char buffer[1024];
    ssize_t bytes_read = read(fd, buffer, sizeof(buffer));
    if (bytes_read == -1) {
        perror("read");
        close(fd);
        return 1;
    }

    close(fd);
    return 0;
}`}
          </pre>

          <h3 className="text-xl font-semibold mt-6 mb-3">Process Management</h3>
          <pre className="bg-gray-900 text-white p-4 rounded-lg overflow-x-auto my-4">
            {`#include <sys/types.h>
#include <unistd.h>

int main() {
    pid_t pid = fork();
    
    if (pid == -1) {
        perror("fork");
        return 1;
    }
    
    if (pid == 0) {
        // Child process
        execl("/bin/ls", "ls", "-l", NULL);
        perror("execl");  // Only reached if execl fails
        return 1;
    } else {
        // Parent process
        wait(NULL);
    }
    
    return 0;
}`}
          </pre>
        </div>

        <div className="bg-white rounded-lg shadow-md p-6 mb-8">
          <h2 className="flex items-center text-2xl font-semibold mb-4">
            <Terminal className="w-6 h-6 text-[#EC6224] mr-2" />
            IPC Mechanisms
          </h2>

          <h3 className="text-xl font-semibold mt-6 mb-3">Shared Memory</h3>
          <pre className="bg-gray-900 text-white p-4 rounded-lg overflow-x-auto my-4">
            {`#include <sys/ipc.h>
#include <sys/shm.h>

int main() {
    key_t key = ftok("shmfile", 65);
    int shmid = shmget(key, 1024, 0666|IPC_CREAT);
    char *str = (char*) shmat(shmid, (void*)0, 0);
    
    printf("Write Data : ");
    gets(str);
    
    printf("Data written: %s\\n", str);
    shmdt(str);
    
    return 0;
}`}
          </pre>

          <h3 className="text-xl font-semibold mt-6 mb-3">Message Queues</h3>
          <pre className="bg-gray-900 text-white p-4 rounded-lg overflow-x-auto my-4">
            {`#include <sys/msg.h>

struct msg_buffer {
    long msg_type;
    char msg_text[100];
} message;

int main() {
    key_t key = ftok("progfile", 65);
    int msgid = msgget(key, 0666 | IPC_CREAT);
    
    message.msg_type = 1;
    printf("Write Data : ");
    gets(message.msg_text);
    
    msgsnd(msgid, &message, sizeof(message), 0);
    printf("Data sent: %s \\n", message.msg_text);
    
    return 0;
}`}
          </pre>
        </div>

        <div className="bg-white rounded-lg shadow-md p-6">
          <h2 className="flex items-center text-2xl font-semibold mb-4">
            <Database className="w-6 h-6 text-[#EC6224] mr-2" />
            Memory Management
          </h2>

          <h3 className="text-xl font-semibold mt-6 mb-3">Memory Mapping</h3>
          <pre className="bg-gray-900 text-white p-4 rounded-lg overflow-x-auto my-4">
            {`#include <sys/mman.h>
#include <fcntl.h>

int main() {
    int fd = open("file.txt", O_RDWR);
    struct stat sb;
    fstat(fd, &sb);
    
    char *mapped = mmap(NULL, sb.st_size,
                       PROT_READ | PROT_WRITE,
                       MAP_SHARED, fd, 0);
    
    if (mapped == MAP_FAILED) {
        perror("mmap");
        close(fd);
        return 1;
    }
    
    // Modify mapped memory
    mapped[0] = 'H';
    
    munmap(mapped, sb.st_size);
    close(fd);
    return 0;
}`}
          </pre>

          <div className="mt-8 p-4 bg-blue-50 rounded-lg">
            <h4 className="text-lg font-semibold mb-2">Additional Resources</h4>
            <ul className="list-disc list-inside space-y-2">
              <li>
                <a 
                  href="https://man7.org/tlpi/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#EC6224] hover:text-[#232323]"
                >
                  The Linux Programming Interface
                </a>
              </li>
              <li>
                <a 
                  href="https://www.kernel.org/doc/man-pages/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#EC6224] hover:text-[#232323]"
                >
                  Linux Man Pages
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SystemProgramming;