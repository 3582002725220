import React from 'react';
import { Terminal, Code, GitBranch, Bug } from 'lucide-react';
import PageTitle from '../../components/PageTitle';

const KernelDevelopment = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <PageTitle 
        title="Linux Kernel Development"
        description="Guide to Linux kernel development and module programming"
        keywords="Linux kernel, kernel modules, device drivers, kernel programming"
      />

      <h1 className="text-3xl font-bold text-[#232323] mb-6">Linux Kernel Development Guide</h1>

      <div className="prose prose-lg max-w-none">
        <div className="bg-white rounded-lg shadow-md p-6 mb-8">
          <h2 className="flex items-center text-2xl font-semibold mb-4">
            <Code className="w-6 h-6 text-[#EC6224] mr-2" />
            Kernel Module Development
          </h2>
          
          <h3 className="text-xl font-semibold mt-6 mb-3">Basic Module Structure</h3>
          <pre className="bg-gray-900 text-white p-4 rounded-lg overflow-x-auto my-4">
            {`#include <linux/module.h>
#include <linux/kernel.h>
#include <linux/init.h>

static int __init example_init(void)
{
    pr_info("Example module loaded\\n");
    return 0;
}

static void __exit example_exit(void)
{
    pr_info("Example module unloaded\\n");
}

module_init(example_init);
module_exit(example_exit);

MODULE_LICENSE("GPL");
MODULE_AUTHOR("Your Name");
MODULE_DESCRIPTION("Example kernel module");`}</pre>

          <h3 className="text-xl font-semibold mt-6 mb-3">Building Modules</h3>
          <pre className="bg-gray-900 text-white p-4 rounded-lg overflow-x-auto my-4">
            {`# Makefile for kernel module
obj-m += example.o

all:
    make -C /lib/modules/$(shell uname -r)/build M=$(PWD) modules

clean:
    make -C /lib/modules/$(shell uname -r)/build M=$(PWD) clean`}</pre>
        </div>

        <div className="bg-white rounded-lg shadow-md p-6 mb-8">
          <h2 className="flex items-center text-2xl font-semibold mb-4">
            <Terminal className="w-6 h-6 text-[#EC6224] mr-2" />
            Device Driver Development
          </h2>

          <h3 className="text-xl font-semibold mt-6 mb-3">Character Device Driver</h3>
          <pre className="bg-gray-900 text-white p-4 rounded-lg overflow-x-auto my-4">
            {`#include <linux/fs.h>
#include <linux/device.h>
#include <linux/cdev.h>

static struct cdev *example_cdev;
static dev_t dev_num;

static int device_open(struct inode *inode, struct file *file)
{
    pr_info("Device opened\\n");
    return 0;
}

static ssize_t device_read(struct file *file, char __user *user_buffer,
                          size_t size, loff_t *offset)
{
    /* Read implementation */
    return 0;
}

static const struct file_operations fops = {
    .owner = THIS_MODULE,
    .open = device_open,
    .read = device_read,
};

/* Initialize in module_init */
alloc_chrdev_region(&dev_num, 0, 1, "example");
example_cdev = cdev_alloc();
cdev_init(example_cdev, &fops);
cdev_add(example_cdev, dev_num, 1);`}</pre>
        </div>

        <div className="bg-white rounded-lg shadow-md p-6 mb-8">
          <h2 className="flex items-center text-2xl font-semibold mb-4">
            <Bug className="w-6 h-6 text-[#EC6224] mr-2" />
            Kernel Debugging
          </h2>

          <h3 className="text-xl font-semibold mt-6 mb-3">Debug Tools</h3>
          <pre className="bg-gray-900 text-white p-4 rounded-lg overflow-x-auto my-4">
            {`# Enable kernel debug messages
echo 8 > /proc/sys/kernel/printk

# View kernel messages
dmesg -w

# Using KGDB
# Add to kernel parameters:
kgdboc=ttyS0,115200 kgdbwait

# Connect with GDB:
gdb ./vmlinux
(gdb) target remote /dev/ttyS0`}</pre>

          <h3 className="text-xl font-semibold mt-6 mb-3">Tracing and Profiling</h3>
          <pre className="bg-gray-900 text-white p-4 rounded-lg overflow-x-auto my-4">
            {`# Using ftrace
cd /sys/kernel/debug/tracing
echo function > current_tracer
cat trace

# Using perf
perf record -a sleep 10
perf report

# Using SystemTap
stap -e 'probe kernel.function("sys_open") {printf("open syscall\\n")}'`}</pre>
        </div>

        <div className="bg-white rounded-lg shadow-md p-6">
          <h2 className="flex items-center text-2xl font-semibold mb-4">
            <GitBranch className="w-6 h-6 text-[#EC6224] mr-2" />
            Contributing to the Kernel
          </h2>

          <h3 className="text-xl font-semibold mt-6 mb-3">Development Process</h3>
          <ul className="list-disc pl-6 space-y-2">
            <li>Subscribe to LKML (Linux Kernel Mailing List)</li>
            <li>Follow coding standards in Documentation/process/coding-style.rst</li>
            <li>Use git send-email for patch submission</li>
            <li>Respond to reviewer feedback</li>
          </ul>

          <h3 className="text-xl font-semibold mt-6 mb-3">Patch Submission</h3>
          <pre className="bg-gray-900 text-white p-4 rounded-lg overflow-x-auto my-4">
            {`# Generate patch
git format-patch -1 HEAD

# Check patch style
scripts/checkpatch.pl --file patch.patch

# Send patch
git send-email --to=linux-kernel@vger.kernel.org patch.patch`}</pre>

          <div className="mt-8 p-4 bg-blue-50 rounded-lg">
            <h4 className="text-lg font-semibold mb-2">Additional Resources</h4>
            <ul className="list-disc list-inside space-y-2">
              <li>
                <a 
                  href="https://www.kernel.org/doc/html/latest/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#EC6224] hover:text-[#232323]"
                >
                  Linux Kernel Documentation
                </a>
              </li>
              <li>
                <a 
                  href="https://lwn.net/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#EC6224] hover:text-[#232323]"
                >
                  LWN.net - Linux Weekly News
                </a>
              </li>
              <li>
                <a 
                  href="https://kernelnewbies.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#EC6224] hover:text-[#232323]"
                >
                  Kernel Newbies
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KernelDevelopment;