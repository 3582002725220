import { NewsItem } from './types';
import { formatDate } from './dateUtils';
import { getRandomImage } from './imageUtils';
import { rssFeeds } from '../config/rssFeeds';

export class FeedParser {
  static parseRSSItem(item: Element, feed: typeof rssFeeds[0]): NewsItem | null {
    try {
      // Get link
      let link = '';
      const linkElement = item.querySelector('link');
      
      if (linkElement) {
        link = linkElement.getAttribute('href') || linkElement.textContent || '';
        const cdataMatch = link.match(/<!\[CDATA\[(.*?)\]\]>/);
        if (cdataMatch) {
          link = cdataMatch[1].trim();
        }
      }

      if (!link || link.split('/').length <= 4) {
        console.warn(`Invalid link found in ${feed.name}`);
        return null;
      }

      // Get publication date
      const pubDateElement = item.querySelector('pubDate, published, updated, dc\\:date');
      const pubDate = pubDateElement ? formatDate(pubDateElement.textContent || '') : '';
      
      if (!pubDate) {
        console.warn(`No valid date found in ${feed.name}`);
        return null;
      }

      // Get title
      const title = item.querySelector('title')?.textContent?.trim() || '';
      if (!title) {
        console.warn(`No title found in ${feed.name}`);
        return null;
      }

      // Get description
      let description = '';
      const descElement = item.querySelector('description, summary, content\\:encoded');
      if (descElement) {
        description = descElement.textContent || '';
        description = description
          .replace(/<!\[CDATA\[(.*?)\]\]>/gs, '$1')
          .replace(/<[^>]+>/g, '')
          .trim();
        
        if (description.length > 200) {
          description = description.substring(0, 197) + '...';
        }
      }

      // Always use random Unsplash image from our predefined list
      const imageUrl = getRandomImage(feed.category, feed.defaultImages);

      return {
        title,
        description,
        link,
        date: pubDate,
        source: feed.name,
        category: feed.category,
        imageUrl
      };
    } catch (error) {
      console.error(`Error parsing RSS item from ${feed.name}:`, error);
      return null;
    }
  }
}