import React from 'react';
import { Terminal, Shield, Settings, Server, Code, Database } from 'lucide-react';
import PageTitle from '../components/PageTitle';
import { Link } from 'react-router-dom';

function Linux() {
  const resources = {
    administration: [
      {
        title: "System Performance Tuning Guide",
        link: "/linux/system-performance",
        description: "Comprehensive guide to optimizing Linux system performance, CPU, memory, and I/O"
      },
      {
        title: "System Monitoring",
        link: "/linux/monitoring",
        description: "Setting up and using Prometheus and other tools for system monitoring"
      }
    ],
    security: [
      {
        title: "Security Hardening Guide",
        link: "/linux/security-hardening",
        description: "Best practices for securing Linux systems, access control, and system monitoring"
      },
      {
        title: "SELinux Configuration",
        link: "/linux/selinux",
        description: "Understanding and implementing SELinux for enhanced system security"
      }
    ],
    development: [
      {
        title: "Kernel Development Guide",
        link: "/linux/kernel-development",
        description: "Guide to developing Linux kernel modules, drivers, and kernel programming"
      },
      {
        title: "System Programming",
        link: "/linux/system-programming",
        description: "System calls, low-level programming, and Linux system development"
      }
    ]
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <PageTitle 
        title="Linux"
        description="Comprehensive Linux resources, tutorials, and insights for system administration, security, and development."
        keywords="Linux, system administration, Linux security, Linux development, open source"
      />
      
      <div className="text-center mb-12">
        <Terminal className="w-16 h-16 text-[#EC6224] mx-auto mb-4" />
        <h1 className="text-4xl font-bold text-[#232323] mb-4">Linux Resources</h1>
        <p className="text-xl text-gray-600 max-w-3xl mx-auto">
          Dive deep into Linux systems with our comprehensive guides, tutorials, and insights.
          From kernel development to system administration, we cover everything you need to
          master Linux.
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        <div className="bg-white p-6 rounded-lg shadow-md">
          <Terminal className="w-8 h-8 text-[#EC6224] mb-4" />
          <h2 className="text-xl font-semibold mb-3">System Administration</h2>
          <ul className="text-gray-600 space-y-4">
            {resources.administration.map((item, index) => (
              <li key={index} className="border-b border-gray-100 pb-3">
                <h3 className="font-medium text-[#232323] mb-1">{item.title}</h3>
                <p className="text-sm mb-2">{item.description}</p>
                <Link
                  to={item.link}
                  className="text-[#EC6224] hover:text-[#232323] transition-colors text-sm font-medium inline-flex items-center"
                >
                  Read More 
                  <svg className="w-4 h-4 ml-1" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                  </svg>
                </Link>
              </li>
            ))}
          </ul>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-md">
          <Shield className="w-8 h-8 text-[#EC6224] mb-4" />
          <h2 className="text-xl font-semibold mb-3">Security</h2>
          <ul className="text-gray-600 space-y-4">
            {resources.security.map((item, index) => (
              <li key={index} className="border-b border-gray-100 pb-3">
                <h3 className="font-medium text-[#232323] mb-1">{item.title}</h3>
                <p className="text-sm mb-2">{item.description}</p>
                <Link
                  to={item.link}
                  className="text-[#EC6224] hover:text-[#232323] transition-colors text-sm font-medium inline-flex items-center"
                >
                  Read More 
                  <svg className="w-4 h-4 ml-1" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                  </svg>
                </Link>
              </li>
            ))}
          </ul>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-md">
          <Code className="w-8 h-8 text-[#EC6224] mb-4" />
          <h2 className="text-xl font-semibold mb-3">Development</h2>
          <ul className="text-gray-600 space-y-4">
            {resources.development.map((item, index) => (
              <li key={index} className="border-b border-gray-100 pb-3">
                <h3 className="font-medium text-[#232323] mb-1">{item.title}</h3>
                <p className="text-sm mb-2">{item.description}</p>
                <Link
                  to={item.link}
                  className="text-[#EC6224] hover:text-[#232323] transition-colors text-sm font-medium inline-flex items-center"
                >
                  Read More 
                  <svg className="w-4 h-4 ml-1" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                  </svg>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Linux;