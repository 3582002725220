import React from 'react';
import { Cloud, Server, Shield, Network } from 'lucide-react';
import PageTitle from '../../components/PageTitle';

const CloudArchitecture = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <PageTitle 
        title="Cloud Architecture"
        description="Modern cloud architecture patterns and best practices"
        keywords="cloud architecture, AWS, microservices, serverless, DevOps"
      />

      <h1 className="text-3xl font-bold text-[#232323] mb-6">Cloud Architecture Patterns</h1>

      <div className="prose prose-lg max-w-none">
        <div className="bg-white rounded-lg shadow-md p-6 mb-8">
          <h2 className="flex items-center text-2xl font-semibold mb-4">
            <Server className="w-6 h-6 text-[#EC6224] mr-2" />
            Microservices Architecture
          </h2>
          
          <h3 className="text-xl font-semibold mt-6 mb-3">Service Design</h3>
          <pre className="bg-gray-900 text-white p-4 rounded-lg overflow-x-auto my-4">
            {`// Example microservice using Express and TypeScript
import express from 'express';
import { Router } from 'express';
import { OrderService } from './services/order';

const router: Router = express.Router();
const orderService = new OrderService();

router.post('/orders', async (req, res) => {
  try {
    const order = await orderService.createOrder(req.body);
    res.status(201).json(order);
  } catch (error) {
    res.status(400).json({ error: error.message });
  }
});

export default router;`}
          </pre>

          <h3 className="text-xl font-semibold mt-6 mb-3">Service Discovery</h3>
          <pre className="bg-gray-900 text-white p-4 rounded-lg overflow-x-auto my-4">
            {`# Docker Compose configuration for service discovery
version: '3.8'
services:
  orders:
    build: ./orders
    environment:
      - CONSUL_HOST=consul
    depends_on:
      - consul
  
  consul:
    image: consul:latest
    ports:
      - "8500:8500"
    command: agent -server -bootstrap -ui -client=0.0.0.0`}
          </pre>
        </div>

        <div className="bg-white rounded-lg shadow-md p-6 mb-8">
          <h2 className="flex items-center text-2xl font-semibold mb-4">
            <Cloud className="w-6 h-6 text-[#EC6224] mr-2" />
            Serverless Architecture
          </h2>

          <h3 className="text-xl font-semibold mt-6 mb-3">AWS Lambda Function</h3>
          <pre className="bg-gray-900 text-white p-4 rounded-lg overflow-x-auto my-4">
            {`// TypeScript AWS Lambda function
import { APIGatewayProxyHandler } from 'aws-lambda';
import { DynamoDB } from 'aws-sdk';

const dynamodb = new DynamoDB.DocumentClient();

export const handler: APIGatewayProxyHandler = async (event) => {
  try {
    const data = JSON.parse(event.body || '{}');
    
    await dynamodb.put({
      TableName: process.env.TABLE_NAME!,
      Item: {
        id: Date.now().toString(),
        ...data
      }
    }).promise();

    return {
      statusCode: 201,
      body: JSON.stringify({ message: 'Item created' })
    };
  } catch (error) {
    return {
      statusCode: 500,
      body: JSON.stringify({ error: error.message })
    };
  }
};`}
          </pre>

          <h3 className="text-xl font-semibold mt-6 mb-3">Infrastructure as Code</h3>
          <pre className="bg-gray-900 text-white p-4 rounded-lg overflow-x-auto my-4">
            {`# Terraform configuration for Lambda
resource "aws_lambda_function" "api_handler" {
  filename         = "function.zip"
  function_name    = "api-handler"
  role             = aws_iam_role.lambda_role.arn
  handler          = "index.handler"
  runtime          = "nodejs18.x"

  environment {
    variables = {
      TABLE_NAME = aws_dynamodb_table.main.name
    }
  }
}`}
          </pre>
        </div>

        <div className="bg-white rounded-lg shadow-md p-6 mb-8">
          <h2 className="flex items-center text-2xl font-semibold mb-4">
            <Network className="w-6 h-6 text-[#EC6224] mr-2" />
            Container Orchestration
          </h2>

          <h3 className="text-xl font-semibold mt-6 mb-3">Kubernetes Configuration</h3>
          <pre className="bg-gray-900 text-white p-4 rounded-lg overflow-x-auto my-4">
            {`apiVersion: apps/v1
kind: Deployment
metadata:
  name: api-service
spec:
  replicas: 3
  selector:
    matchLabels:
      app: api
  template:
    metadata:
      labels:
        app: api
    spec:
      containers:
      - name: api
        image: api-service:latest
        ports:
        - containerPort: 3000
        env:
        - name: DATABASE_URL
          valueFrom:
            secretKeyRef:
              name: db-secret
              key: url`}
          </pre>

          <h3 className="text-xl font-semibold mt-6 mb-3">Service Mesh</h3>
          <pre className="bg-gray-900 text-white p-4 rounded-lg overflow-x-auto my-4">
            {`apiVersion: networking.istio.io/v1alpha3
kind: VirtualService
metadata:
  name: api-routes
spec:
  hosts:
  - api.example.com
  gateways:
  - api-gateway
  http:
  - match:
    - uri:
        prefix: /api/v1
    route:
    - destination:
        host: api-service
        subset: v1
        port:
          number: 3000`}
          </pre>
        </div>

        <div className="bg-white rounded-lg shadow-md p-6">
          <h2 className="flex items-center text-2xl font-semibold mb-4">
            <Shield className="w-6 h-6 text-[#EC6224] mr-2" />
            Security Patterns
          </h2>

          <h3 className="text-xl font-semibold mt-6 mb-3">Zero Trust Architecture</h3>
          <pre className="bg-gray-900 text-white p-4 rounded-lg overflow-x-auto my-4">
            {`// OAuth2 middleware example
import { verify } from 'jsonwebtoken';
import { Request, Response, NextFunction } from 'express';

export const authenticate = async (
  req: Request,
  res: Response,
  next: NextFunction
) => {
  try {
    const token = req.headers.authorization?.split(' ')[1];
    if (!token) {
      throw new Error('No token provided');
    }

    const decoded = verify(token, process.env.JWT_SECRET!);
    req.user = decoded;
    next();
  } catch (error) {
    res.status(401).json({ error: 'Unauthorized' });
  }
};`}
          </pre>

          <div className="mt-8 p-4 bg-blue-50 rounded-lg">
            <h4 className="text-lg font-semibold mb-2">Additional Resources</h4>
            <ul className="list-disc list-inside space-y-2">
              <li>
                <a 
                  href="https://12factor.net/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#EC6224] hover:text-[#232323]"
                >
                  The Twelve-Factor App Methodology
                </a>
              </li>
              <li>
                <a 
                  href="https://microservices.io/patterns/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#EC6224] hover:text-[#232323]"
                >
                  Microservices Patterns
                </a>
              </li>
              <li>
                <a 
                  href="https://aws.amazon.com/architecture/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#EC6224] hover:text-[#232323]"
                >
                  AWS Architecture Center
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CloudArchitecture;