import React from 'react';
import { Share2, Facebook, Linkedin } from 'lucide-react';
import { X } from './Icons';

interface NewsCardProps {
  title: string;
  description: string;
  imageUrl: string;
  date: string;
  source: string;
  link: string;
}

const NewsCard: React.FC<NewsCardProps> = ({
  title,
  description,
  imageUrl,
  date,
  source,
  link,
}) => {
  const shareUrls = {
    twitter: `https://twitter.com/intent/tweet?text=${encodeURIComponent(title)}&url=${encodeURIComponent(link)}`,
    facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(link)}`,
    linkedin: `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(link)}`
  };

  const handleShare = (platform: keyof typeof shareUrls) => {
    window.open(shareUrls[platform], '_blank', 'noopener,noreferrer');
  };

  return (
    <div className="bg-white rounded-lg shadow-lg overflow-hidden transition-transform hover:scale-[1.02]">
      <img
        src={imageUrl}
        alt={title}
        className="w-full h-48 object-cover"
        loading="lazy"
      />
      <div className="p-4">
        <div className="flex justify-between items-center mb-2">
          <span className="text-sm text-[#EC6224]">{source}</span>
          <span className="text-sm text-gray-500">{date}</span>
        </div>
        <h3 className="text-lg font-semibold mb-2 text-[#232323] line-clamp-2">{title}</h3>
        <p className="text-gray-600 mb-4 line-clamp-3">{description}</p>
        <div className="flex justify-between items-center">
          <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center px-6 py-2 bg-[#232323] text-white rounded-lg hover:bg-[#EC6224] transition-colors transform hover:scale-105 shadow-md"
          >
            <span>Read Article</span>
            <svg
              className="w-4 h-4 ml-2"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M14 5l7 7m0 0l-7 7m7-7H3"
              />
            </svg>
          </a>
          <div className="flex space-x-2">
            <button
              onClick={() => handleShare('twitter')}
              className="p-2 rounded-full hover:bg-gray-100 transition-colors"
              aria-label="Share on X (Twitter)"
            >
              <X className="w-5 h-5" />
            </button>
            <button
              onClick={() => handleShare('facebook')}
              className="p-2 rounded-full hover:bg-gray-100 transition-colors"
              aria-label="Share on Facebook"
            >
              <Facebook className="w-5 h-5 text-[#1877F2]" />
            </button>
            <button
              onClick={() => handleShare('linkedin')}
              className="p-2 rounded-full hover:bg-gray-100 transition-colors"
              aria-label="Share on LinkedIn"
            >
              <Linkedin className="w-5 h-5 text-[#0A66C2]" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsCard;