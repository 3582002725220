import React, { useState, useEffect } from 'react';
import NewsCard from './NewsCard';
import Pagination from './Pagination';
import FeedCache, { NewsItem } from '../utils/feedCache';

const RSSFeed: React.FC = () => {
  const [newsItems, setNewsItems] = useState<NewsItem[]>([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9;

  useEffect(() => {
    const loadNews = async () => {
      try {
        const feedCache = FeedCache.getInstance();
        const items = await feedCache.getNews();
        
        if (items && items.length > 0) {
          setNewsItems(items);
        }
      } catch (error) {
        console.warn('Error loading news:', error);
      } finally {
        setLoading(false);
      }
    };

    loadNews();
    const refreshInterval = setInterval(loadNews, 15 * 60 * 1000);

    return () => clearInterval(refreshInterval);
  }, []);

  const totalPages = Math.ceil(newsItems.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = newsItems.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-[400px]">
        <div className="animate-spin rounded-full h-12 w-12 border-4 border-[#EC6224] border-t-transparent"></div>
      </div>
    );
  }

  if (!loading && newsItems.length === 0) {
    return (
      <div className="text-center py-12">
        <h3 className="text-xl text-gray-600">No articles available at the moment.</h3>
        <p className="text-gray-500 mt-2">Please check back later for updates.</p>
      </div>
    );
  }

  return (
    <div id="news-feed" className="container mx-auto">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
        {currentItems.map((item, index) => (
          <NewsCard key={`${item.link}-${index}`} {...item} />
        ))}
      </div>
      {newsItems.length > itemsPerPage && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      )}
    </div>
  );
}

export default RSSFeed;