import React from 'react';
import { LineChart, Bell, Database, Network } from 'lucide-react';
import PageTitle from '../../components/PageTitle';

const Monitoring = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <PageTitle 
        title="System Monitoring"
        description="Setting up and using Prometheus and other tools for system monitoring"
        keywords="system monitoring, Prometheus, Grafana, Linux monitoring"
      />

      <h1 className="text-3xl font-bold text-[#232323] mb-6">System Monitoring Guide</h1>

      <div className="prose prose-lg max-w-none">
        <div className="bg-white rounded-lg shadow-md p-6 mb-8">
          <h2 className="flex items-center text-2xl font-semibold mb-4">
            <LineChart className="w-6 h-6 text-[#EC6224] mr-2" />
            Prometheus Setup
          </h2>
          
          <h3 className="text-xl font-semibold mt-6 mb-3">Installation</h3>
          <pre className="bg-gray-900 text-white p-4 rounded-lg overflow-x-auto my-4">
            {`# Download Prometheus
wget https://github.com/prometheus/prometheus/releases/download/v2.37.0/prometheus-2.37.0.linux-amd64.tar.gz

# Extract and setup
tar xvfz prometheus-*.tar.gz
cd prometheus-*

# Configure prometheus.yml
global:
  scrape_interval: 15s

scrape_configs:
  - job_name: 'prometheus'
    static_configs:
      - targets: ['localhost:9090']

  - job_name: 'node'
    static_configs:
      - targets: ['localhost:9100']`}
          </pre>
        </div>

        <div className="bg-white rounded-lg shadow-md p-6 mb-8">
          <h2 className="flex items-center text-2xl font-semibold mb-4">
            <Bell className="w-6 h-6 text-[#EC6224] mr-2" />
            Alert Configuration
          </h2>

          <h3 className="text-xl font-semibold mt-6 mb-3">Alerting Rules</h3>
          <pre className="bg-gray-900 text-white p-4 rounded-lg overflow-x-auto my-4">
            {`# alerts.yml
groups:
- name: example
  rules:
  - alert: HighCPUUsage
    expr: 100 - (avg by(instance) (rate(node_cpu_seconds_total{mode="idle"}[2m])) * 100) > 80
    for: 5m
    labels:
      severity: warning
    annotations:
      summary: High CPU usage detected
      description: CPU usage is above 80% for 5 minutes`}
          </pre>
        </div>

        <div className="bg-white rounded-lg shadow-md p-6 mb-8">
          <h2 className="flex items-center text-2xl font-semibold mb-4">
            <Database className="w-6 h-6 text-[#EC6224] mr-2" />
            Grafana Integration
          </h2>

          <h3 className="text-xl font-semibold mt-6 mb-3">Dashboard Setup</h3>
          <pre className="bg-gray-900 text-white p-4 rounded-lg overflow-x-auto my-4">
            {`# Install Grafana
sudo apt-get install -y software-properties-common
sudo add-apt-repository "deb https://packages.grafana.com/oss/deb stable main"
wget -q -O - https://packages.grafana.com/gpg.key | sudo apt-key add -
sudo apt-get update
sudo apt-get install grafana

# Start Grafana
sudo systemctl start grafana-server
sudo systemctl enable grafana-server`}
          </pre>

          <h3 className="text-xl font-semibold mt-6 mb-3">PromQL Examples</h3>
          <pre className="bg-gray-900 text-white p-4 rounded-lg overflow-x-auto my-4">
            {`# CPU Usage
100 - (avg by (instance) (rate(node_cpu_seconds_total{mode="idle"}[5m])) * 100)

# Memory Usage
(node_memory_MemTotal_bytes - node_memory_MemAvailable_bytes) / node_memory_MemTotal_bytes * 100

# Disk Usage
100 - ((node_filesystem_avail_bytes * 100) / node_filesystem_size_bytes)`}
          </pre>
        </div>

        <div className="bg-white rounded-lg shadow-md p-6">
          <h2 className="flex items-center text-2xl font-semibold mb-4">
            <Network className="w-6 h-6 text-[#EC6224] mr-2" />
            Node Exporter
          </h2>

          <h3 className="text-xl font-semibold mt-6 mb-3">Setup</h3>
          <pre className="bg-gray-900 text-white p-4 rounded-lg overflow-x-auto my-4">
            {`# Download and install node exporter
wget https://github.com/prometheus/node_exporter/releases/download/v1.3.1/node_exporter-1.3.1.linux-amd64.tar.gz
tar xvfz node_exporter-*.tar.gz
cd node_exporter-*

# Create systemd service
sudo tee /etc/systemd/system/node_exporter.service<<EOF
[Unit]
Description=Node Exporter
After=network.target

[Service]
User=node_exporter
ExecStart=/usr/local/bin/node_exporter

[Install]
WantedBy=multi-user.target
EOF

# Start service
sudo systemctl daemon-reload
sudo systemctl start node_exporter
sudo systemctl enable node_exporter`}
          </pre>

          <div className="mt-8 p-4 bg-blue-50 rounded-lg">
            <h4 className="text-lg font-semibold mb-2">Additional Resources</h4>
            <ul className="list-disc list-inside space-y-2">
              <li>
                <a 
                  href="https://prometheus.io/docs/introduction/overview/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#EC6224] hover:text-[#232323]"
                >
                  Prometheus Documentation
                </a>
              </li>
              <li>
                <a 
                  href="https://grafana.com/docs/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#EC6224] hover:text-[#232323]"
                >
                  Grafana Documentation
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Monitoring;