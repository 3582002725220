import React from 'react';
import { Code, Layout, Palette, Box } from 'lucide-react';
import PageTitle from '../../components/PageTitle';

function Frontend() {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <PageTitle 
        title="Frontend Development"
        description="Modern frontend development practices, frameworks, and tools"
        keywords="frontend, React, Vue, TypeScript, web development"
      />
      
      <div className="text-center mb-12">
        <Layout className="w-16 h-16 text-[#EC6224] mx-auto mb-4" />
        <h1 className="text-4xl font-bold text-[#232323] mb-4">Frontend Development</h1>
        <p className="text-xl text-gray-600 max-w-3xl mx-auto">
          Explore modern frontend development practices, frameworks, and tools for building
          exceptional user experiences.
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        <div className="bg-white p-6 rounded-lg shadow-md">
          <Code className="w-8 h-8 text-[#EC6224] mb-4" />
          <h2 className="text-xl font-semibold mb-3">Modern JavaScript</h2>
          <ul className="space-y-2 text-gray-600">
            <li>• ES6+ Features and Best Practices</li>
            <li>• TypeScript Integration</li>
            <li>• Module Systems</li>
            <li>• Async Programming</li>
            <li>• Performance Optimization</li>
          </ul>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-md">
          <Box className="w-8 h-8 text-[#EC6224] mb-4" />
          <h2 className="text-xl font-semibold mb-3">Frontend Frameworks</h2>
          <ul className="space-y-2 text-gray-600">
            <li>• React and Next.js</li>
            <li>• Vue and Nuxt</li>
            <li>• State Management</li>
            <li>• Routing Solutions</li>
            <li>• Component Architecture</li>
          </ul>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-md">
          <Palette className="w-8 h-8 text-[#EC6224] mb-4" />
          <h2 className="text-xl font-semibold mb-3">UI/UX Development</h2>
          <ul className="space-y-2 text-gray-600">
            <li>• Modern CSS Techniques</li>
            <li>• Responsive Design</li>
            <li>• Animation and Transitions</li>
            <li>• Accessibility (a11y)</li>
            <li>• Design Systems</li>
          </ul>
        </div>
      </div>

      <div className="mt-12 grid grid-cols-1 gap-8">
        <div className="bg-white p-8 rounded-lg shadow-md">
          <h2 className="text-2xl font-bold mb-6">Learning Resources</h2>
          <div className="space-y-4">
            <div>
              <h3 className="text-lg font-semibold mb-2">Documentation</h3>
              <ul className="list-disc list-inside space-y-2 text-gray-600">
                <li><a href="https://react.dev" target="_blank" rel="noopener noreferrer" className="text-[#EC6224] hover:text-[#232323]">React Documentation</a></li>
                <li><a href="https://vuejs.org" target="_blank" rel="noopener noreferrer" className="text-[#EC6224] hover:text-[#232323]">Vue.js Documentation</a></li>
                <li><a href="https://www.typescriptlang.org/docs" target="_blank" rel="noopener noreferrer" className="text-[#EC6224] hover:text-[#232323]">TypeScript Handbook</a></li>
              </ul>
            </div>
            
            <div>
              <h3 className="text-lg font-semibold mb-2">Tutorials and Courses</h3>
              <ul className="list-disc list-inside space-y-2 text-gray-600">
                <li><a href="https://fullstackopen.com" target="_blank" rel="noopener noreferrer" className="text-[#EC6224] hover:text-[#232323]">Full Stack Open</a></li>
                <li><a href="https://javascript.info" target="_blank" rel="noopener noreferrer" className="text-[#EC6224] hover:text-[#232323]">Modern JavaScript Tutorial</a></li>
                <li><a href="https://css-tricks.com" target="_blank" rel="noopener noreferrer" className="text-[#EC6224] hover:text-[#232323]">CSS Tricks</a></li>
              </ul>
            </div>
            
            <div>
              <h3 className="text-lg font-semibold mb-2">Tools and Resources</h3>
              <ul className="list-disc list-inside space-y-2 text-gray-600">
                <li><a href="https://vitejs.dev" target="_blank" rel="noopener noreferrer" className="text-[#EC6224] hover:text-[#232323]">Vite - Next Generation Frontend Tooling</a></li>
                <li><a href="https://prettier.io" target="_blank" rel="noopener noreferrer" className="text-[#EC6224] hover:text-[#232323]">Prettier - Code Formatter</a></li>
                <li><a href="https://eslint.org" target="_blank" rel="noopener noreferrer" className="text-[#EC6224] hover:text-[#232323]">ESLint - Code Quality Tool</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Frontend;