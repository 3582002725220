import { NewsItem } from './types';

export class NewsUpdater {
  private static readonly UPDATE_INTERVAL = 3600000; // 1 hour
  private static instance: NewsUpdater;
  private currentNews: NewsItem[];
  private lastUpdate: number = 0;

  private constructor() {
    this.currentNews = this.generateNews();
    this.startUpdateCycle();
  }

  public static getInstance(): NewsUpdater {
    if (!NewsUpdater.instance) {
      NewsUpdater.instance = new NewsUpdater();
    }
    return NewsUpdater.instance;
  }

  private startUpdateCycle(): void {
    setInterval(() => {
      this.currentNews = this.generateNews();
      this.lastUpdate = Date.now();
    }, NewsUpdater.UPDATE_INTERVAL);
  }

  private generateNews(): NewsItem[] {
    const baseTime = Date.now();
    
    return [
      {
        title: "Quantum Error Correction Breakthrough Promises More Stable Qubits",
        description: "Researchers achieve significant milestone in quantum error correction, demonstrating a new method that could lead to more reliable quantum computers.",
        link: "https://quantumzeitgeist.com/quantum-error-correction-breakthrough/",
        date: new Date(baseTime - 1000 * 60 * 60).toISOString(), // 1 hour ago
        source: "Quantum Zeitgeist",
        category: "quantum",
        imageUrl: "https://images.unsplash.com/photo-1635070041078-e363dbe005cb"
      },
      {
        title: "IBM Unveils New Quantum Computing Roadmap",
        description: "IBM announces ambitious plans for scaling quantum computers, including new architectures for reducing quantum errors and improving qubit connectivity.",
        link: "https://quantumcomputingreport.com/ibm-quantum-roadmap/",
        date: new Date(baseTime - 1000 * 60 * 120).toISOString(), // 2 hours ago
        source: "Quantum Computing Report",
        category: "quantum",
        imageUrl: "https://images.unsplash.com/photo-1628595351029-c2bf17511435"
      },
      {
        title: "Quantum Advantage Demonstrated in Machine Learning Task",
        description: "Scientists demonstrate quantum advantage in a practical machine learning application, showing significant speedup over classical computers.",
        link: "https://quantumzeitgeist.com/quantum-advantage-ml/",
        date: new Date(baseTime - 1000 * 60 * 180).toISOString(), // 3 hours ago
        source: "Quantum Zeitgeist",
        category: "quantum",
        imageUrl: "https://images.unsplash.com/photo-1517976487492-5750f3195933"
      },
      {
        title: "Ubuntu 24.04 LTS Development Kicks Off with Major Updates",
        description: "The next long-term support release of Ubuntu brings significant improvements to desktop performance and security features.",
        link: "https://www.omgubuntu.co.uk/ubuntu-24-04",
        date: new Date(baseTime - 1000 * 60 * 240).toISOString(), // 4 hours ago
        source: "OMG Ubuntu",
        category: "linux",
        imageUrl: "https://images.unsplash.com/photo-1629654297299-c8506221ca97"
      }
    ];
  }

  public getDefaultNews(): NewsItem[] {
    return this.currentNews;
  }
}