import React, { useEffect } from 'react';

interface PageTitleProps {
  title: string;
  description: string;
  keywords?: string;
}

const PageTitle: React.FC<PageTitleProps> = ({ title, description, keywords }) => {
  useEffect(() => {
    // Update page title
    document.title = `Quantumbytz | ${title}`;
    
    // Update meta description
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', description);
    }
    
    // Update meta keywords
    if (keywords) {
      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if (metaKeywords) {
        metaKeywords.setAttribute('content', keywords);
      }
    }
    
    // Update OpenGraph tags
    const ogTitle = document.querySelector('meta[property="og:title"]');
    const ogDescription = document.querySelector('meta[property="og:description"]');
    if (ogTitle) {
      ogTitle.setAttribute('content', `Quantumbytz | ${title}`);
    }
    if (ogDescription) {
      ogDescription.setAttribute('content', description);
    }
    
    // Update Twitter tags
    const twitterTitle = document.querySelector('meta[name="twitter:title"]');
    const twitterDescription = document.querySelector('meta[name="twitter:description"]');
    if (twitterTitle) {
      twitterTitle.setAttribute('content', `Quantumbytz | ${title}`);
    }
    if (twitterDescription) {
      twitterDescription.setAttribute('content', description);
    }
  }, [title, description, keywords]);

  return null;
};

export default PageTitle;