import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import QuantumNews from './pages/QuantumNews';
import Linux from './pages/Linux';
import TechStack from './pages/TechStack';
import About from './pages/About';

// Linux Pages
import SystemPerformance from './pages/linux/SystemPerformance';
import SecurityHardening from './pages/linux/SecurityHardening';
import KernelDevelopment from './pages/linux/KernelDevelopment';
import Monitoring from './pages/linux/Monitoring';
import SELinux from './pages/linux/SELinux';
import SystemProgramming from './pages/linux/SystemProgramming';

// Tech Stack Pages
import Frontend from './pages/tech/Frontend';
import Backend from './pages/tech/Backend';
import CloudArchitecture from './pages/tech/CloudArchitecture';
import WebDevelopment from './pages/tech/WebDevelopment';
import AIandML from './pages/tech/AIandML';
import BlockchainDev from './pages/tech/BlockchainDev';
import DevOps from './pages/tech/DevOps';
import MobileDev from './pages/tech/MobileDev';

// Quantum Pages
import QuantumComputing from './pages/quantum/QuantumComputing';
import QuantumAlgorithms from './pages/quantum/QuantumAlgorithms';
import QuantumHardware from './pages/quantum/QuantumHardware';
import QuantumSoftware from './pages/quantum/QuantumSoftware';
import QuantumResearch from './pages/quantum/QuantumResearch';

function App() {
  return (
    <Router>
      <div className="min-h-screen bg-[#FFFFFF] flex flex-col">
        <Navbar />
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/quantum-news" element={<QuantumNews />} />
            <Route path="/linux" element={<Linux />} />
            <Route path="/tech-stack" element={<TechStack />} />
            <Route path="/about" element={<About />} />
            
            {/* Linux Routes */}
            <Route path="/linux/system-performance" element={<SystemPerformance />} />
            <Route path="/linux/monitoring" element={<Monitoring />} />
            <Route path="/linux/security-hardening" element={<SecurityHardening />} />
            <Route path="/linux/selinux" element={<SELinux />} />
            <Route path="/linux/kernel-development" element={<KernelDevelopment />} />
            <Route path="/linux/system-programming" element={<SystemProgramming />} />
            
            {/* Tech Stack Routes */}
            <Route path="/tech/frontend" element={<Frontend />} />
            <Route path="/tech/backend" element={<Backend />} />
            <Route path="/tech/cloud" element={<CloudArchitecture />} />
            <Route path="/tech/web" element={<WebDevelopment />} />
            <Route path="/tech/ai" element={<AIandML />} />
            <Route path="/tech/blockchain" element={<BlockchainDev />} />
            <Route path="/tech/devops" element={<DevOps />} />
            <Route path="/tech/mobile" element={<MobileDev />} />
            
            {/* Quantum Routes */}
            <Route path="/quantum/computing" element={<QuantumComputing />} />
            <Route path="/quantum/algorithms" element={<QuantumAlgorithms />} />
            <Route path="/quantum/hardware" element={<QuantumHardware />} />
            <Route path="/quantum/software" element={<QuantumSoftware />} />
            <Route path="/quantum/research" element={<QuantumResearch />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;