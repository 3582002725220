import React from 'react';
import { Smartphone, Code, Layout, Share2 } from 'lucide-react';
import PageTitle from '../../components/PageTitle';

const MobileDev = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <PageTitle 
        title="Mobile Development"
        description="Guide to modern mobile app development"
        keywords="mobile development, iOS, Android, React Native"
      />

      <h1 className="text-3xl font-bold text-[#232323] mb-6">Mobile Development</h1>

      <div className="prose prose-lg max-w-none">
        {/* Content implementation */}
        <p>Content coming soon...</p>
      </div>
    </div>
  );
};

export default MobileDev;