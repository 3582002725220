import { NewsItem, CacheStorage } from './types';
import { FeedParser } from './feedParser';
import { rssFeeds } from '../config/rssFeeds';
import { NewsUpdater } from './newsUpdater';

class FeedCache {
  private static instance: FeedCache;
  private cache: NewsItem[] = [];
  private lastUpdate: number = 0;
  private updateInterval: NodeJS.Timeout | null = null;
  private readonly UPDATE_INTERVAL = 3600000; // 1 hour
  private readonly MAX_AGE = 2592000000; // 30 days
  private readonly CORS_PROXIES = [
    'https://api.allorigins.win/raw?url=',
    'https://corsproxy.io/?',
    'https://cors.eu.org/'
  ];
  private isUpdating: boolean = false;
  private newsUpdater: NewsUpdater;

  private constructor() {
    this.newsUpdater = NewsUpdater.getInstance();
    this.cache = this.newsUpdater.getDefaultNews();
    this.loadFromStorage();
    this.startBackgroundUpdates();
    this.updateCache();
  }

  private async loadFromStorage(): Promise<void> {
    try {
      const stored = localStorage.getItem('feedCache');
      if (stored) {
        const { items, timestamp } = JSON.parse(stored) as CacheStorage;
        if (Date.now() - timestamp < this.MAX_AGE) {
          this.cache = this.sortByDate([...items]);
          this.lastUpdate = timestamp;
          return;
        }
      }
    } catch (error) {
      console.warn('Failed to load feed cache from localStorage:', error);
    }
  }

  private sortByDate(items: NewsItem[]): NewsItem[] {
    return items.sort((a, b) => {
      const dateA = new Date(a.date).getTime();
      const dateB = new Date(b.date).getTime();
      return dateB - dateA; // Sort in descending order (newest first)
    });
  }

  public static getInstance(): FeedCache {
    if (!FeedCache.instance) {
      FeedCache.instance = new FeedCache();
    }
    return FeedCache.instance;
  }

  private startBackgroundUpdates(): void {
    if (this.updateInterval) {
      clearInterval(this.updateInterval);
    }
    
    this.updateInterval = setInterval(() => {
      this.updateCache();
    }, this.UPDATE_INTERVAL);

    window.addEventListener('unload', () => {
      if (this.updateInterval) {
        clearInterval(this.updateInterval);
      }
    });
  }

  private async fetchWithRetry(url: string, attempts = 0): Promise<Response> {
    if (attempts >= this.CORS_PROXIES.length) {
      throw new Error(`Failed to fetch ${url} after trying all CORS proxies`);
    }

    try {
      const proxyUrl = this.CORS_PROXIES[attempts] + encodeURIComponent(url);
      const response = await fetch(proxyUrl, {
        headers: {
          'Accept': 'application/rss+xml, application/xml, text/xml, application/atom+xml',
        }
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      return response;
    } catch (error) {
      console.warn(`CORS proxy ${attempts + 1} failed for ${url}:`, error);
      return this.fetchWithRetry(url, attempts + 1);
    }
  }

  private async fetchFeeds(): Promise<NewsItem[]> {
    try {
      const fetchPromises = rssFeeds.map(async (feed) => {
        try {
          const response = await this.fetchWithRetry(feed.url);
          const data = await response.text();
          
          if (!data || data.trim().length === 0) {
            throw new Error('Empty response received');
          }

          const parser = new DOMParser();
          const xml = parser.parseFromString(data, 'text/xml');
          
          if (xml.querySelector('parsererror')) {
            throw new Error('XML parsing error');
          }

          const items = Array.from(xml.querySelectorAll('item, entry'));
          
          if (items.length === 0) {
            console.warn(`No items found in feed: ${feed.name}`);
          }

          return Promise.all(items.map(item => FeedParser.parseRSSItem(item, feed)))
            .then(results => results.filter((item): item is NewsItem => item !== null));
        } catch (error) {
          console.error(`Error fetching ${feed.name}:`, error);
          return [];
        }
      });

      const allItems = (await Promise.all(fetchPromises)).flat();
      
      if (allItems.length === 0) {
        console.warn('No items fetched from any feed');
        return this.sortByDate([...this.newsUpdater.getDefaultNews()]);
      }

      return this.sortByDate([...allItems]);
    } catch (error) {
      console.error('Error fetching feeds:', error);
      return this.sortByDate([...this.newsUpdater.getDefaultNews()]);
    }
  }

  private async updateCache(): Promise<void> {
    if (this.isUpdating) {
      return;
    }

    const now = Date.now();
    try {
      this.isUpdating = true;
      const newItems = await this.fetchFeeds();
      if (newItems.length > 0) {
        this.cache = this.sortByDate([...newItems]);
        this.lastUpdate = now;
        try {
          localStorage.setItem('feedCache', JSON.stringify({
            items: this.cache,
            timestamp: this.lastUpdate
          }));
        } catch (error) {
          console.warn('Failed to store feed cache in localStorage:', error);
        }
      }
    } finally {
      this.isUpdating = false;
    }
  }

  public async getNews(): Promise<NewsItem[]> {
    return this.sortByDate([...this.cache]);
  }
}

export default FeedCache;