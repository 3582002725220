import React from 'react';

interface LogoProps {
  className?: string;
  alt?: string;
}

export const Logo: React.FC<LogoProps> = ({ 
  className = "w-8 h-8",
  alt = "QuantumBytz Logo" 
}) => (
  <img
    src="/quantumbytz-logo.png"
    alt={alt}
    className={`${className} object-contain`}
    loading="eager"
    width={32}
    height={32}
  />
);

export default Logo;