import React from 'react';
import { Link, Lock, Code, Database } from 'lucide-react';
import PageTitle from '../../components/PageTitle';

const BlockchainDev = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <PageTitle 
        title="Blockchain Development"
        description="Guide to blockchain development and Web3 technologies"
        keywords="blockchain, Web3, smart contracts, DApps"
      />

      <h1 className="text-3xl font-bold text-[#232323] mb-6">Blockchain Development</h1>

      <div className="prose prose-lg max-w-none">
        {/* Content implementation */}
        <p>Content coming soon...</p>
      </div>
    </div>
  );
};

export default BlockchainDev;