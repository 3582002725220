import React from 'react';
import { Binary, Search, Lock, Calculator } from 'lucide-react';
import PageTitle from '../../components/PageTitle';

const QuantumAlgorithms = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <PageTitle 
        title="Quantum Algorithms"
        description="Comprehensive guide to quantum algorithms and their applications"
        keywords="quantum algorithms, Shor's algorithm, Grover's algorithm, quantum computing"
      />

      <h1 className="text-3xl font-bold text-[#232323] mb-6">Quantum Algorithms</h1>

      <div className="prose prose-lg max-w-none">
        <div className="bg-white rounded-lg shadow-md p-6 mb-8">
          <h2 className="flex items-center text-2xl font-semibold mb-4">
            <Binary className="w-6 h-6 text-[#EC6224] mr-2" />
            Shor's Algorithm
          </h2>
          
          <h3 className="text-xl font-semibold mt-6 mb-3">Integer Factorization</h3>
          <p>
            Shor's algorithm provides an exponential speedup over classical algorithms for integer factorization.
            Here's a simplified implementation using Qiskit:
          </p>
          <pre className="bg-gray-900 text-white p-4 rounded-lg overflow-x-auto my-4">
            {`from qiskit import QuantumCircuit, QuantumRegister, ClassicalRegister

def create_shor_circuit(n: int) -> QuantumCircuit:
    """Create quantum circuit for Shor's algorithm"""
    # Number of qubits needed = 2 * len(bin(n)[2:])
    num_qubits = 2 * len(bin(n)[2:])
    
    # Create quantum and classical registers
    qr = QuantumRegister(num_qubits)
    cr = ClassicalRegister(num_qubits)
    circuit = QuantumCircuit(qr, cr)
    
    # Apply quantum Fourier transform
    circuit.h(range(num_qubits))
    
    # Add modular exponentiation
    # (Implementation details omitted for brevity)
    
    # Inverse quantum Fourier transform
    circuit.inverse_qft(range(num_qubits))
    
    # Measure all qubits
    circuit.measure(qr, cr)
    
    return circuit`}
          </pre>

          <h3 className="text-xl font-semibold mt-6 mb-3">Applications</h3>
          <ul className="list-disc pl-6 space-y-2">
            <li>Breaking RSA encryption</li>
            <li>Quantum cryptography</li>
            <li>Number theory research</li>
          </ul>
        </div>

        <div className="bg-white rounded-lg shadow-md p-6 mb-8">
          <h2 className="flex items-center text-2xl font-semibold mb-4">
            <Search className="w-6 h-6 text-[#EC6224] mr-2" />
            Grover's Algorithm
          </h2>

          <h3 className="text-xl font-semibold mt-6 mb-3">Quantum Search</h3>
          <p>
            Grover's algorithm provides a quadratic speedup for unstructured search problems.
            Implementation example:
          </p>
          <pre className="bg-gray-900 text-white p-4 rounded-lg overflow-x-auto my-4">
            {`def create_grover_circuit(oracle: QuantumCircuit, n: int) -> QuantumCircuit:
    """Create Grover's algorithm circuit"""
    # Initialize circuit with n qubits
    qr = QuantumRegister(n)
    cr = ClassicalRegister(n)
    circuit = QuantumCircuit(qr, cr)
    
    # Initialize superposition
    circuit.h(range(n))
    
    # Number of iterations = π/4 * sqrt(2^n)
    iterations = int(np.pi/4 * np.sqrt(2**n))
    
    for _ in range(iterations):
        # Apply oracle
        circuit.compose(oracle)
        
        # Apply diffusion operator
        circuit.h(range(n))
        circuit.x(range(n))
        circuit.h(n-1)
        circuit.mct(list(range(n-1)), n-1)
        circuit.h(n-1)
        circuit.x(range(n))
        circuit.h(range(n))
    
    # Measure results
    circuit.measure(qr, cr)
    
    return circuit`}
          </pre>

          <h3 className="text-xl font-semibold mt-6 mb-3">Applications</h3>
          <ul className="list-disc pl-6 space-y-2">
            <li>Database searching</li>
            <li>Optimization problems</li>
            <li>Quantum machine learning</li>
          </ul>
        </div>

        <div className="bg-white rounded-lg shadow-md p-6 mb-8">
          <h2 className="flex items-center text-2xl font-semibold mb-4">
            <Calculator className="w-6 h-6 text-[#EC6224] mr-2" />
            Quantum Phase Estimation
          </h2>

          <h3 className="text-xl font-semibold mt-6 mb-3">Implementation</h3>
          <pre className="bg-gray-900 text-white p-4 rounded-lg overflow-x-auto my-4">
            {`def quantum_phase_estimation(unitary: QuantumCircuit, precision: int) -> QuantumCircuit:
    """Quantum Phase Estimation circuit"""
    # Create registers
    counting_qubits = QuantumRegister(precision)
    state_qubits = QuantumRegister(unitary.num_qubits)
    classical_bits = ClassicalRegister(precision)
    
    # Create circuit
    qpe = QuantumCircuit(counting_qubits, state_qubits, classical_bits)
    
    # Initialize counting qubits in superposition
    qpe.h(counting_qubits)
    
    # Apply controlled unitary operations
    for i in range(precision):
        qpe.append(unitary.power(2**i), 
                  [counting_qubits[i]] + state_qubits[:])
    
    # Apply inverse QFT
    qpe.inverse_qft(counting_qubits)
    
    # Measure counting qubits
    qpe.measure(counting_qubits, classical_bits)
    
    return qpe`}
          </pre>

          <h3 className="text-xl font-semibold mt-6 mb-3">Applications</h3>
          <ul className="list-disc pl-6 space-y-2">
            <li>Quantum chemistry simulations</li>
            <li>Period finding</li>
            <li>Eigenvalue estimation</li>
          </ul>
        </div>

        <div className="bg-white rounded-lg shadow-md p-6">
          <h2 className="flex items-center text-2xl font-semibold mb-4">
            <Lock className="w-6 h-6 text-[#EC6224] mr-2" />
            Quantum Error Correction Algorithms
          </h2>

          <h3 className="text-xl font-semibold mt-6 mb-3">Surface Code Implementation</h3>
          <pre className="bg-gray-900 text-white p-4 rounded-lg overflow-x-auto my-4">
            {`def create_surface_code(d: int) -> QuantumCircuit:
    """Create a distance-d surface code"""
    # Number of physical qubits = d^2 + (d-1)^2
    n_physical = d**2 + (d-1)**2
    
    # Create registers
    data_qubits = QuantumRegister(d**2, 'data')
    syndrome_qubits = QuantumRegister((d-1)**2, 'synd')
    syndrome_bits = ClassicalRegister((d-1)**2, 'meas')
    
    # Create circuit
    circuit = QuantumCircuit(data_qubits, syndrome_qubits, syndrome_bits)
    
    # Add stabilizer measurements
    # X-stabilizers
    for i in range((d-1)**2):
        circuit.h(syndrome_qubits[i])
        for j in adjacent_data_qubits(i, d):
            circuit.cx(syndrome_qubits[i], data_qubits[j])
        circuit.h(syndrome_qubits[i])
        circuit.measure(syndrome_qubits[i], syndrome_bits[i])
    
    return circuit`}
          </pre>

          <div className="mt-8 p-4 bg-blue-50 rounded-lg">
            <h4 className="text-lg font-semibold mb-2">Additional Resources</h4>
            <ul className="list-disc list-inside space-y-2">
              <li>
                <a 
                  href="https://arxiv.org/abs/quant-ph/9508027"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#EC6224] hover:text-[#232323]"
                >
                  Original Shor's Algorithm Paper
                </a>
              </li>
              <li>
                <a 
                  href="https://arxiv.org/abs/quant-ph/9605043"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#EC6224] hover:text-[#232323]"
                >
                  Grover's Algorithm Paper
                </a>
              </li>
              <li>
                <a 
                  href="https://qiskit.org/textbook/ch-algorithms/quantum-phase-estimation.html"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#EC6224] hover:text-[#232323]"
                >
                  Qiskit Phase Estimation Tutorial
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuantumAlgorithms;