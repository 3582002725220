import React from 'react';
import { Shield, Lock, FileKey, AlertTriangle } from 'lucide-react';
import PageTitle from '../../components/PageTitle';

const SecurityHardening = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <PageTitle 
        title="Linux Security Hardening"
        description="Comprehensive guide to hardening Linux systems"
        keywords="Linux security, system hardening, security best practices"
      />

      <h1 className="text-3xl font-bold text-[#232323] mb-6">Linux Security Hardening Guide</h1>

      <div className="prose prose-lg max-w-none">
        <div className="bg-white rounded-lg shadow-md p-6 mb-8">
          <h2 className="flex items-center text-2xl font-semibold mb-4">
            <Shield className="w-6 h-6 text-[#EC6224] mr-2" />
            System Access Control
          </h2>
          
          <h3 className="text-xl font-semibold mt-6 mb-3">User Management</h3>
          <pre className="bg-gray-900 text-white p-4 rounded-lg overflow-x-auto my-4">
            {`# Set password policies
sudo nano /etc/login.defs

# Important settings:
PASS_MAX_DAYS   90
PASS_MIN_DAYS   7
PASS_WARN_AGE   7

# Configure PAM password requirements
sudo nano /etc/pam.d/common-password
password requisite pam_pwquality.so retry=3 minlen=12 difok=3`}
          </pre>

          <h3 className="text-xl font-semibold mt-6 mb-3">SSH Hardening</h3>
          <pre className="bg-gray-900 text-white p-4 rounded-lg overflow-x-auto my-4">
            {`# SSH configuration
sudo nano /etc/ssh/sshd_config

# Recommended settings:
PermitRootLogin no
PasswordAuthentication no
X11Forwarding no
MaxAuthTries 3
Protocol 2`}
          </pre>
        </div>

        <div className="bg-white rounded-lg shadow-md p-6 mb-8">
          <h2 className="flex items-center text-2xl font-semibold mb-4">
            <Lock className="w-6 h-6 text-[#EC6224] mr-2" />
            Firewall Configuration
          </h2>

          <h3 className="text-xl font-semibold mt-6 mb-3">UFW Setup</h3>
          <pre className="bg-gray-900 text-white p-4 rounded-lg overflow-x-auto my-4">
            {`# Install UFW
sudo apt install ufw

# Basic UFW configuration
sudo ufw default deny incoming
sudo ufw default allow outgoing
sudo ufw allow ssh
sudo ufw allow http
sudo ufw allow https

# Enable UFW
sudo ufw enable

# Check status
sudo ufw status verbose`}
          </pre>

          <h3 className="text-xl font-semibold mt-6 mb-3">IPTables Rules</h3>
          <pre className="bg-gray-900 text-white p-4 rounded-lg overflow-x-auto my-4">
            {`# Basic iptables ruleset
# Drop all incoming traffic by default
iptables -P INPUT DROP
iptables -P FORWARD DROP
iptables -P OUTPUT ACCEPT

# Allow established connections
iptables -A INPUT -m state --state ESTABLISHED,RELATED -j ACCEPT

# Allow SSH
iptables -A INPUT -p tcp --dport 22 -j ACCEPT

# Save rules
sudo iptables-save > /etc/iptables/rules.v4`}
          </pre>
        </div>

        <div className="bg-white rounded-lg shadow-md p-6 mb-8">
          <h2 className="flex items-center text-2xl font-semibold mb-4">
            <FileKey className="w-6 h-6 text-[#EC6224] mr-2" />
            File System Security
          </h2>

          <h3 className="text-xl font-semibold mt-6 mb-3">File Permissions</h3>
          <pre className="bg-gray-900 text-white p-4 rounded-lg overflow-x-auto my-4">
            {`# Secure important files
chmod 644 /etc/passwd
chmod 600 /etc/shadow
chmod 600 /etc/gshadow
chmod 644 /etc/group

# Find world-writable files
find / -xdev -type f -perm -0002

# Find SUID/SGID files
find / -xdev \\( -perm -4000 -o -perm -2000 \\)`}
          </pre>

          <h3 className="text-xl font-semibold mt-6 mb-3">Disk Encryption</h3>
          <pre className="bg-gray-900 text-white p-4 rounded-lg overflow-x-auto my-4">
            {`# Create encrypted volume
sudo cryptsetup luksFormat /dev/sdb1

# Open encrypted volume
sudo cryptsetup luksOpen /dev/sdb1 secure_storage

# Create filesystem
sudo mkfs.ext4 /dev/mapper/secure_storage

# Mount encrypted volume
sudo mount /dev/mapper/secure_storage /mnt/secure`}
          </pre>
        </div>

        <div className="bg-white rounded-lg shadow-md p-6">
          <h2 className="flex items-center text-2xl font-semibold mb-4">
            <AlertTriangle className="w-6 h-6 text-[#EC6224] mr-2" />
            System Monitoring
          </h2>

          <h3 className="text-xl font-semibold mt-6 mb-3">Audit Configuration</h3>
          <pre className="bg-gray-900 text-white p-4 rounded-lg overflow-x-auto my-4">
            {`# Install auditd
sudo apt install auditd

# Configure audit rules
sudo nano /etc/audit/rules.d/audit.rules

# Example rules:
-w /etc/passwd -p wa -k identity
-w /etc/group -p wa -k identity
-w /etc/shadow -p wa -k identity
-w /etc/sudoers -p wa -k sudo_actions

# Restart auditd
sudo service auditd restart

# View audit logs
sudo ausearch -k identity`}
          </pre>

          <div className="mt-8 p-4 bg-blue-50 rounded-lg">
            <h4 className="text-lg font-semibold mb-2">Additional Resources</h4>
            <ul className="list-disc list-inside space-y-2">
              <li>
                <a 
                  href="https://www.cisecurity.org/benchmark/linux"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#EC6224] hover:text-[#232323]"
                >
                  CIS Linux Benchmarks
                </a>
              </li>
              <li>
                <a 
                  href="https://wiki.archlinux.org/title/Security"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#EC6224] hover:text-[#232323]"
                >
                  Arch Linux Security Guide
                </a>
              </li>
              <li>
                <a 
                  href="https://access.redhat.com/documentation/en-us/red_hat_enterprise_linux/8/html/security_hardening/index"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#EC6224] hover:text-[#232323]"
                >
                  Red Hat Security Hardening Guide
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecurityHardening;