import React from 'react';
import { Cpu, Users, Globe, Mail, Github } from 'lucide-react';
import { X } from '../components/Icons';
import PageTitle from '../components/PageTitle';

function About() {
  const team = [
    {
      name: "Dr. Sarah Chen",
      role: "Quantum Computing Lead",
      bio: "Ph.D. in Quantum Physics with 10+ years of research experience in quantum computing and quantum error correction.",
      image: "https://images.unsplash.com/photo-1494790108377-be9c29b29330",
      social: {
        github: "https://github.com/yodabytz",
        twitter: "https://x.com/quantumbytz",
        email: "sarah@quantumbytz.com"
      }
    },
    {
      name: "Alex Rodriguez",
      role: "Linux Systems Architect",
      bio: "Red Hat Certified Architect with extensive experience in enterprise Linux deployments and cloud infrastructure.",
      image: "https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d",
      social: {
        github: "https://github.com/yodabytz",
        twitter: "https://x.com/quantumbytz",
        email: "alex@quantumbytz.com"
      }
    },
    {
      name: "Dr. James Wilson",
      role: "Technology Director",
      bio: "Former Google Tech Lead with expertise in distributed systems and quantum algorithm development.",
      image: "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e",
      social: {
        github: "https://github.com/yodabytz",
        twitter: "https://x.com/quantumbytz",
        email: "james@quantumbytz.com"
      }
    }
  ];

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <PageTitle 
        title="About"
        description="Learn about QuantumBytz and our mission to bridge quantum computing with modern technology."
        keywords="quantum computing, team, about us, technology company"
      />

      <div className="text-center mb-12">
        <h1 className="text-4xl font-bold text-[#232323] mb-4">About QuantumBytz</h1>
        <p className="text-xl text-gray-600 max-w-2xl mx-auto">
          Bridging the gap between quantum computing, Linux, and emerging technologies
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
        <div className="bg-white p-6 rounded-lg shadow-md">
          <Cpu className="w-8 h-8 text-[#EC6224] mb-4" />
          <h2 className="text-xl font-semibold mb-3">Our Mission</h2>
          <p className="text-gray-600">
            To democratize quantum computing knowledge and provide cutting-edge insights into Linux and modern technology stacks.
          </p>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-md">
          <Users className="w-8 h-8 text-[#EC6224] mb-4" />
          <h2 className="text-xl font-semibold mb-3">Our Community</h2>
          <p className="text-gray-600">
            Building a vibrant ecosystem of developers, researchers, and technology enthusiasts passionate about quantum computing and open-source technologies.
          </p>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-md">
          <Globe className="w-8 h-8 text-[#EC6224] mb-4" />
          <h2 className="text-xl font-semibold mb-3">Global Impact</h2>
          <p className="text-gray-600">
            Contributing to the advancement of quantum computing and open-source technologies through education, research, and collaboration.
          </p>
        </div>
      </div>

      <div className="mb-16">
        <h2 className="text-3xl font-bold text-center mb-8">Our Team</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {team.map((member) => (
            <div key={member.name} className="bg-white p-6 rounded-lg shadow-md">
              <img
                src={member.image}
                alt={member.name}
                className="w-32 h-32 rounded-full mx-auto mb-4 object-cover"
              />
              <h3 className="text-xl font-semibold text-center mb-2">{member.name}</h3>
              <p className="text-[#EC6224] text-center mb-3">{member.role}</p>
              <p className="text-gray-600 text-center mb-4">{member.bio}</p>
              <div className="flex justify-center space-x-4">
                <a href={member.social.github} target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-[#EC6224]">
                  <Github className="w-5 h-5" />
                </a>
                <a href={member.social.twitter} target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-[#EC6224]">
                  <X className="w-5 h-5" />
                </a>
                <a href={`mailto:${member.social.email}`} className="text-gray-600 hover:text-[#EC6224]">
                  <Mail className="w-5 h-5" />
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="bg-white p-8 rounded-lg shadow-md">
        <h2 className="text-2xl font-bold mb-4">Connect With Us</h2>
        <p className="text-gray-600 mb-6">
          Join our community and stay connected with the latest developments in quantum computing and technology.
        </p>
        <div className="flex flex-wrap justify-center gap-4">
          <a
            href="https://github.com/yodabytz"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center px-6 py-3 bg-[#232323] text-white rounded-lg hover:bg-[#EC6224] transition-colors"
          >
            <Github className="w-5 h-5 mr-2" />
            GitHub
          </a>
          <a
            href="https://x.com/quantumbytz"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center px-6 py-3 bg-[#232323] text-white rounded-lg hover:bg-[#EC6224] transition-colors"
          >
            <X className="w-5 h-5 mr-2" />
            Twitter
          </a>
          <a
            href="mailto:info@quantumbytz.com"
            className="flex items-center px-6 py-3 bg-[#232323] text-white rounded-lg hover:bg-[#EC6224] transition-colors"
          >
            <Mail className="w-5 h-5 mr-2" />
            Email Us
          </a>
        </div>
      </div>
    </div>
  );
}

export default About;