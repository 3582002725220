import React from 'react';
import { Link } from 'react-router-dom';
import { Atom, Cpu, Binary, Microscope } from 'lucide-react';
import PageTitle from '../components/PageTitle';
import RSSFeed from '../components/RSSFeed';

function QuantumNews() {
  const sections = [
    {
      title: "Quantum Hardware",
      icon: <Cpu className="w-8 h-8 text-[#EC6224] mb-4" />,
      description: "Latest developments in quantum processors, control systems, and quantum memory.",
      link: "/quantum/hardware",
      topics: [
        "Superconducting qubits",
        "Ion trap quantum computers",
        "Photonic quantum systems",
        "Quantum error correction"
      ]
    },
    {
      title: "Quantum Software",
      icon: <Binary className="w-8 h-8 text-[#EC6224] mb-4" />,
      description: "Updates on quantum algorithms, programming frameworks, and development tools.",
      link: "/quantum/software",
      topics: [
        "Quantum programming languages",
        "Quantum development frameworks",
        "Quantum simulation tools",
        "Quantum software optimization"
      ]
    },
    {
      title: "Research Breakthroughs",
      icon: <Microscope className="w-8 h-8 text-[#EC6224] mb-4" />,
      description: "Recent discoveries and advancements in quantum computing research.",
      link: "/quantum/research",
      topics: [
        "Quantum supremacy experiments",
        "Error correction advances",
        "New quantum algorithms",
        "Quantum applications"
      ]
    }
  ];

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <PageTitle 
        title="Quantum News"
        description="Stay updated with the latest breakthroughs in quantum computing technology"
        keywords="quantum computing, quantum news, quantum hardware, quantum software, quantum research"
      />

      <div className="text-center mb-12">
        <Atom className="w-16 h-16 text-[#EC6224] mx-auto mb-4" />
        <h1 className="text-4xl font-bold text-[#232323] mb-4">Quantum Computing News</h1>
        <p className="text-xl text-gray-600 max-w-2xl mx-auto">
          Stay at the forefront of quantum computing developments with our curated news and insights
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
        {sections.map((section) => (
          <Link 
            key={section.title}
            to={section.link}
            className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow"
          >
            <div className="text-center">
              {section.icon}
              <h2 className="text-xl font-semibold mb-3">{section.title}</h2>
              <p className="text-gray-600 mb-4">{section.description}</p>
              <ul className="text-left text-gray-600 space-y-2">
                {section.topics.map((topic) => (
                  <li key={topic} className="flex items-center">
                    <span className="w-2 h-2 bg-[#EC6224] rounded-full mr-2"></span>
                    {topic}
                  </li>
                ))}
              </ul>
            </div>
          </Link>
        ))}
      </div>

      <div className="bg-gray-50 rounded-lg p-8 mb-16">
        <h2 className="text-2xl font-bold text-center mb-8">Latest Quantum Computing News</h2>
        <RSSFeed />
      </div>

      <div className="bg-white rounded-lg shadow-md p-8">
        <h2 className="text-2xl font-bold mb-6">Featured Resources</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div>
            <h3 className="text-xl font-semibold mb-4">Getting Started</h3>
            <ul className="space-y-3">
              <li>
                <Link 
                  to="/quantum/computing"
                  className="text-[#EC6224] hover:text-[#232323] flex items-center"
                >
                  Quantum Computing Fundamentals
                  <svg className="w-4 h-4 ml-1" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                  </svg>
                </Link>
              </li>
              <li>
                <Link 
                  to="/quantum/algorithms"
                  className="text-[#EC6224] hover:text-[#232323] flex items-center"
                >
                  Quantum Algorithms
                  <svg className="w-4 h-4 ml-1" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                  </svg>
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="text-xl font-semibold mb-4">Advanced Topics</h3>
            <ul className="space-y-3">
              <li>
                <Link 
                  to="/quantum/hardware"
                  className="text-[#EC6224] hover:text-[#232323] flex items-center"
                >
                  Quantum Hardware Deep Dive
                  <svg className="w-4 h-4 ml-1" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                  </svg>
                </Link>
              </li>
              <li>
                <Link 
                  to="/quantum/software"
                  className="text-[#EC6224] hover:text-[#232323] flex items-center"
                >
                  Quantum Software Development
                  <svg className="w-4 h-4 ml-1" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                  </svg>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuantumNews;