import React from 'react';
import { Cpu, Binary, Zap, Lock } from 'lucide-react';
import PageTitle from '../../components/PageTitle';

const QuantumComputing = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <PageTitle 
        title="Quantum Computing Fundamentals"
        description="Deep dive into quantum computing principles, qubits, and quantum circuits"
        keywords="quantum computing, qubits, quantum gates, quantum circuits"
      />

      <h1 className="text-3xl font-bold text-[#232323] mb-6">Quantum Computing Fundamentals</h1>

      <div className="prose prose-lg max-w-none">
        <div className="bg-white rounded-lg shadow-md p-6 mb-8">
          <h2 className="flex items-center text-2xl font-semibold mb-4">
            <Cpu className="w-6 h-6 text-[#EC6224] mr-2" />
            Quantum Bits (Qubits)
          </h2>
          
          <h3 className="text-xl font-semibold mt-6 mb-3">Understanding Qubits</h3>
          <p>
            Unlike classical bits that can only be in state 0 or 1, qubits can exist in a superposition
            of both states simultaneously. The state of a qubit can be represented as:
          </p>
          <pre className="bg-gray-900 text-white p-4 rounded-lg overflow-x-auto my-4">
            |ψ⟩ = α|0⟩ + β|1⟩
            where |α|² + |β|² = 1
          </pre>

          <h3 className="text-xl font-semibold mt-6 mb-3">Qubit Implementation</h3>
          <ul className="list-disc pl-6 space-y-2">
            <li>Superconducting circuits (IBM, Google)</li>
            <li>Trapped ions (IonQ, Honeywell)</li>
            <li>Photonic qubits (PsiQuantum, Xanadu)</li>
            <li>Topological qubits (Microsoft)</li>
          </ul>
        </div>

        <div className="bg-white rounded-lg shadow-md p-6 mb-8">
          <h2 className="flex items-center text-2xl font-semibold mb-4">
            <Binary className="w-6 h-6 text-[#EC6224] mr-2" />
            Quantum Gates
          </h2>

          <h3 className="text-xl font-semibold mt-6 mb-3">Single-Qubit Gates</h3>
          <pre className="bg-gray-900 text-white p-4 rounded-lg overflow-x-auto my-4">
            {`# Hadamard Gate (H)
H = 1/√2 * [1  1]
         [1 -1]

# Pauli-X Gate (NOT)
X = [0 1]
    [1 0]

# Phase Gate (S)
S = [1 0]
    [0 i]`}
          </pre>

          <h3 className="text-xl font-semibold mt-6 mb-3">Multi-Qubit Gates</h3>
          <pre className="bg-gray-900 text-white p-4 rounded-lg overflow-x-auto my-4">
            {`# CNOT Gate
CNOT = [1 0 0 0]
       [0 1 0 0]
       [0 0 0 1]
       [0 0 1 0]

# SWAP Gate
SWAP = [1 0 0 0]
       [0 0 1 0]
       [0 1 0 0]
       [0 0 0 1]`}
          </pre>
        </div>

        <div className="bg-white rounded-lg shadow-md p-6 mb-8">
          <h2 className="flex items-center text-2xl font-semibold mb-4">
            <Zap className="w-6 h-6 text-[#EC6224] mr-2" />
            Quantum Circuits
          </h2>

          <h3 className="text-xl font-semibold mt-6 mb-3">Circuit Components</h3>
          <ul className="list-disc pl-6 space-y-2">
            <li>Initialization (|0⟩ state preparation)</li>
            <li>Gate operations (unitary transformations)</li>
            <li>Measurement (projective measurements)</li>
            <li>Classical control and feedback</li>
          </ul>

          <h3 className="text-xl font-semibold mt-6 mb-3">Example Circuit: Bell State</h3>
          <pre className="bg-gray-900 text-white p-4 rounded-lg overflow-x-auto my-4">
            {`q0: ─────H─────●─────
                          │
q1: ─────────────X─────

Creates the state:
|ψ⟩ = 1/√2(|00⟩ + |11⟩)`}
          </pre>
        </div>

        <div className="bg-white rounded-lg shadow-md p-6">
          <h2 className="flex items-center text-2xl font-semibold mb-4">
            <Lock className="w-6 h-6 text-[#EC6224] mr-2" />
            Error Correction
          </h2>

          <h3 className="text-xl font-semibold mt-6 mb-3">Quantum Error Types</h3>
          <ul className="list-disc pl-6 space-y-2">
            <li>Bit flip errors (X errors)</li>
            <li>Phase flip errors (Z errors)</li>
            <li>Combined bit-phase flip errors (Y errors)</li>
            <li>Decoherence and amplitude damping</li>
          </ul>

          <h3 className="text-xl font-semibold mt-6 mb-3">Error Correction Codes</h3>
          <pre className="bg-gray-900 text-white p-4 rounded-lg overflow-x-auto my-4">
            {`# 3-Qubit Bit Flip Code
|0⟩ → |000⟩
|1⟩ → |111⟩

# 9-Qubit Shor Code
Protects against both X and Z errors`}
          </pre>

          <div className="mt-8 p-4 bg-blue-50 rounded-lg">
            <h4 className="text-lg font-semibold mb-2">Additional Resources</h4>
            <ul className="list-disc list-inside space-y-2">
              <li>
                <a 
                  href="https://qiskit.org/textbook/what-is-quantum.html"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#EC6224] hover:text-[#232323]"
                >
                  IBM Quantum Computing Textbook
                </a>
              </li>
              <li>
                <a 
                  href="https://quantum.country/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#EC6224] hover:text-[#232323]"
                >
                  Quantum Country Tutorial
                </a>
              </li>
              <li>
                <a 
                  href="https://quantumalgorithmzoo.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#EC6224] hover:text-[#232323]"
                >
                  Quantum Algorithm Zoo
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuantumComputing;