import React from 'react';
import { Cpu, Zap, Waves, Gauge } from 'lucide-react';
import PageTitle from '../../components/PageTitle';

const QuantumHardware = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <PageTitle 
        title="Quantum Hardware"
        description="Deep dive into quantum computing hardware architectures and implementations"
        keywords="quantum hardware, qubits, quantum processors, quantum computers"
      />

      <h1 className="text-3xl font-bold text-[#232323] mb-6">Quantum Hardware Technologies</h1>

      <div className="prose prose-lg max-w-none">
        <div className="bg-white rounded-lg shadow-md p-6 mb-8">
          <h2 className="flex items-center text-2xl font-semibold mb-4">
            <Cpu className="w-6 h-6 text-[#EC6224] mr-2" />
            Superconducting Qubits
          </h2>
          
          <h3 className="text-xl font-semibold mt-6 mb-3">Architecture</h3>
          <p>
            Superconducting qubits are artificial atoms made from superconducting electronic circuits.
            They operate at extremely low temperatures (around 20 millikelvin) and use Josephson
            junctions as nonlinear elements.
          </p>

          <h3 className="text-xl font-semibold mt-6 mb-3">Types of Superconducting Qubits</h3>
          <ul className="list-disc pl-6 space-y-2">
            <li>
              <strong>Transmon Qubits:</strong> Most common type, offering good coherence times
              and reduced sensitivity to charge noise
            </li>
            <li>
              <strong>Flux Qubits:</strong> Suitable for quantum annealing and adiabatic quantum
              computation
            </li>
            <li>
              <strong>Phase Qubits:</strong> Historical importance in early quantum computing
              development
            </li>
          </ul>

          <h3 className="text-xl font-semibold mt-6 mb-3">Control Systems</h3>
          <pre className="bg-gray-900 text-white p-4 rounded-lg overflow-x-auto my-4">
            {`# Typical operating parameters
Temperature: ~20 mK
Coherence Time: 100-300 μs
Gate Time: ~10-50 ns
Error Rate: ~10^-3 - 10^-4`}
          </pre>
        </div>

        <div className="bg-white rounded-lg shadow-md p-6 mb-8">
          <h2 className="flex items-center text-2xl font-semibold mb-4">
            <Waves className="w-6 h-6 text-[#EC6224] mr-2" />
            Trapped Ion Qubits
          </h2>

          <h3 className="text-xl font-semibold mt-6 mb-3">Implementation</h3>
          <p>
            Trapped ion qubits use individual atoms held in electromagnetic traps. These systems
            offer extremely long coherence times and high-fidelity gates.
          </p>

          <h3 className="text-xl font-semibold mt-6 mb-3">Key Characteristics</h3>
          <pre className="bg-gray-900 text-white p-4 rounded-lg overflow-x-auto my-4">
            {`# Trapped Ion Parameters
Coherence Time: > 10 seconds
Gate Fidelity: > 99.9%
Operating Temperature: Room temperature
Connectivity: All-to-all coupling possible`}
          </pre>

          <h3 className="text-xl font-semibold mt-6 mb-3">Control Methods</h3>
          <ul className="list-disc pl-6 space-y-2">
            <li>Laser-based quantum gates</li>
            <li>Microwave-based quantum gates</li>
            <li>Sympathetic cooling techniques</li>
            <li>Ion transport in multi-zone traps</li>
          </ul>
        </div>

        <div className="bg-white rounded-lg shadow-md p-6 mb-8">
          <h2 className="flex items-center text-2xl font-semibold mb-4">
            <Zap className="w-6 h-6 text-[#EC6224] mr-2" />
            Photonic Quantum Computing
          </h2>

          <h3 className="text-xl font-semibold mt-6 mb-3">Architecture Overview</h3>
          <p>
            Photonic quantum computers use light particles (photons) as qubits. They can operate
            at room temperature and are naturally compatible with quantum communication systems.
          </p>

          <h3 className="text-xl font-semibold mt-6 mb-3">Components</h3>
          <pre className="bg-gray-900 text-white p-4 rounded-lg overflow-x-auto my-4">
            {`# Key Components
- Single-photon sources
- Linear optical elements
- Phase shifters
- Beam splitters
- Single-photon detectors

# Operating Parameters
Temperature: Room temperature
Wavelength: ~1550 nm (telecom)
Detection Efficiency: > 90%`}
          </pre>
        </div>

        <div className="bg-white rounded-lg shadow-md p-6">
          <h2 className="flex items-center text-2xl font-semibold mb-4">
            <Gauge className="w-6 h-6 text-[#EC6224] mr-2" />
            Performance Metrics
          </h2>

          <h3 className="text-xl font-semibold mt-6 mb-3">Benchmarking</h3>
          <pre className="bg-gray-900 text-white p-4 rounded-lg overflow-x-auto my-4">
            {`# Key Performance Indicators
1. Coherence Time (T2)
2. Gate Fidelity
3. Readout Fidelity
4. Connectivity
5. Quantum Volume

# Example Metrics (2023)
- IBM: 127 qubit processor
- IonQ: 32 algorithmic qubits
- PsiQuantum: Working toward 1M+ qubits`}
          </pre>

          <div className="mt-8 p-4 bg-blue-50 rounded-lg">
            <h4 className="text-lg font-semibold mb-2">Additional Resources</h4>
            <ul className="list-disc list-inside space-y-2">
              <li>
                <a 
                  href="https://arxiv.org/abs/2111.09388"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#EC6224] hover:text-[#232323]"
                >
                  State of the Art in Quantum Hardware
                </a>
              </li>
              <li>
                <a 
                  href="https://www.nature.com/articles/s41586-019-1666-5"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#EC6224] hover:text-[#232323]"
                >
                  Quantum Supremacy Experiment
                </a>
              </li>
              <li>
                <a 
                  href="https://quantum-computing.ibm.com/hardware"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#EC6224] hover:text-[#232323]"
                >
                  IBM Quantum Hardware
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuantumHardware;