import React from 'react';
import { Binary, Code, Database, Cloud, Globe, Cpu, Server, Network } from 'lucide-react';
import PageTitle from '../components/PageTitle';
import { Link } from 'react-router-dom';

function TechStack() {
  const sections = [
    {
      icon: <Code className="w-8 h-8 text-[#EC6224] mb-4" />,
      title: "Frontend Development",
      path: "/tech/frontend",
      items: [
        "Modern React & Next.js",
        "Vue & Nuxt",
        "TypeScript Best Practices",
        "State Management",
        "Performance Optimization",
        "Testing Strategies"
      ]
    },
    {
      icon: <Database className="w-8 h-8 text-[#EC6224] mb-4" />,
      title: "Backend Systems",
      path: "/tech/backend",
      items: [
        "Node.js Architecture",
        "GraphQL APIs",
        "Database Design",
        "Microservices",
        "Message Queues",
        "Security Patterns"
      ]
    },
    {
      icon: <Cloud className="w-8 h-8 text-[#EC6224] mb-4" />,
      title: "Cloud & DevOps",
      path: "/tech/cloud",
      items: [
        "AWS Solutions",
        "Kubernetes Orchestration",
        "Docker Containerization",
        "CI/CD Pipelines",
        "Infrastructure as Code",
        "Monitoring & Logging"
      ]
    },
    {
      icon: <Network className="w-8 h-8 text-[#EC6224] mb-4" />,
      title: "Web3 & Blockchain",
      path: "/tech/web3",
      items: [
        "Smart Contracts",
        "DApp Development",
        "Ethereum & Solana",
        "Web3.js Integration",
        "IPFS Storage",
        "Zero-knowledge Proofs"
      ]
    },
    {
      icon: <Cpu className="w-8 h-8 text-[#EC6224] mb-4" />,
      title: "AI & Machine Learning",
      path: "/tech/ai",
      items: [
        "TensorFlow & PyTorch",
        "NLP Applications",
        "Computer Vision",
        "MLOps Practices",
        "Model Deployment",
        "Edge AI Solutions"
      ]
    },
    {
      icon: <Server className="w-8 h-8 text-[#EC6224] mb-4" />,
      title: "System Architecture",
      path: "/tech/architecture",
      items: [
        "Scalable Systems",
        "High Availability",
        "Distributed Systems",
        "Data Processing",
        "Security Patterns",
        "Performance Tuning"
      ]
    }
  ];

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <PageTitle 
        title="Tech Stack"
        description="Explore modern technology stacks, development practices, and innovative solutions for building next-generation applications."
        keywords="tech stack, software development, cloud computing, DevOps, microservices"
      />
      
      <div className="text-center mb-12">
        <Binary className="w-16 h-16 text-[#EC6224] mx-auto mb-4" />
        <h1 className="text-4xl font-bold text-[#232323] mb-4">Technology Stack</h1>
        <p className="text-xl text-gray-600 max-w-3xl mx-auto">
          Discover comprehensive insights into modern technology stacks, best practices,
          and innovative solutions for building scalable, efficient applications.
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {sections.map((section, index) => (
          <div key={index} className="bg-white p-6 rounded-lg shadow-md">
            {section.icon}
            <h2 className="text-xl font-semibold mb-3">{section.title}</h2>
            <ul className="text-gray-600 space-y-2 mb-4">
              {section.items.map((item, itemIndex) => (
                <li key={itemIndex}>• {item}</li>
              ))}
            </ul>
            <Link
              to={section.path}
              className="inline-flex items-center text-[#EC6224] hover:text-[#232323] transition-colors"
            >
              <span>Learn More</span>
              <svg
                className="w-4 h-4 ml-2"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M14 5l7 7m0 0l-7 7m7-7H3"
                />
              </svg>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}

export default TechStack;