import { FeedConfig } from '../utils/types';

export const rssFeeds: FeedConfig[] = [
  // Quantum Computing Feeds
  {
    name: 'Quantum Computing Report',
    url: 'https://quantumcomputingreport.com/feed/',
    category: 'quantum',
    defaultImages: [
      'https://images.unsplash.com/photo-1635070041078-e363dbe005cb',
      'https://images.unsplash.com/photo-1628595351029-c2bf17511435',
      'https://images.unsplash.com/photo-1517976487492-5750f3195933',
      'https://images.unsplash.com/photo-1666112835145-d79fc3f2e008',
      'https://images.unsplash.com/photo-1644088379091-d574269d422f',
      'https://images.unsplash.com/photo-1616400619175-5beda3a17896',
      'https://images.unsplash.com/photo-1515879218367-8466d910aaa4',
      'https://images.unsplash.com/photo-1451187580459-43490279c0fa',
      'https://images.unsplash.com/photo-1510906594845-bc082582c8cc',
      'https://images.unsplash.com/photo-1509228468518-180dd4864904'
    ]
  },
  {
    name: 'Quantum Zeitgeist',
    url: 'https://quantumzeitgeist.com/feed/',
    category: 'quantum',
    defaultImages: [
      'https://images.unsplash.com/photo-1635070041078-e363dbe005cb',
      'https://images.unsplash.com/photo-1628595351029-c2bf17511435',
      'https://images.unsplash.com/photo-1517976487492-5750f3195933',
      'https://images.unsplash.com/photo-1462331940025-496dfbfc7564',
      'https://images.unsplash.com/photo-1507668077129-56e32842fceb',
      'https://images.unsplash.com/photo-1511174511562-5f7f18b874f8',
      'https://images.unsplash.com/photo-1620641788421-7a1c342ea42e',
      'https://images.unsplash.com/photo-1518432031352-d6fc5c10da5a',
      'https://images.unsplash.com/photo-1667487935540-f59515f6c7fd',
      'https://images.unsplash.com/photo-1451187580459-43490279c0fa'
    ]
  },
  // Linux Distribution Feeds
  {
    name: 'OMG Ubuntu',
    url: 'https://www.omgubuntu.co.uk/feed',
    category: 'linux',
    defaultImages: [
      'https://images.unsplash.com/photo-1629654297299-c8506221ca97',
      'https://images.unsplash.com/photo-1557853197-aefb550b6fdc',
      'https://images.unsplash.com/photo-1618477247222-acbdb0e159b3',
      'https://images.unsplash.com/photo-1544256718-3bcf237f3974',
      'https://images.unsplash.com/photo-1555066931-4365d14bab8c',
      'https://images.unsplash.com/photo-1531297484001-80022131f5a1',
      'https://images.unsplash.com/photo-1607799279861-4dd421887fb3',
      'https://images.unsplash.com/photo-1640552435388-a54879e72b28',
      'https://images.unsplash.com/photo-1613677135043-a2512fbf49fa',
      'https://images.unsplash.com/photo-1537432376769-00f5c2f4c8d2'
    ]
  },
  {
    name: "It's FOSS",
    url: 'https://itsfoss.com/feed/',
    category: 'linux',
    defaultImages: [
      'https://images.unsplash.com/photo-1629654297299-c8506221ca97',
      'https://images.unsplash.com/photo-1557853197-aefb550b6fdc',
      'https://images.unsplash.com/photo-1618477247222-acbdb0e159b3',
      'https://images.unsplash.com/photo-1515879218367-8466d910aaa4',
      'https://images.unsplash.com/photo-1555066931-4365d14bab8c',
      'https://images.unsplash.com/photo-1544256718-3bcf237f3974',
      'https://images.unsplash.com/photo-1607799279861-4dd421887fb3',
      'https://images.unsplash.com/photo-1537432376769-00f5c2f4c8d2',
      'https://images.unsplash.com/photo-1613677135043-a2512fbf49fa',
      'https://images.unsplash.com/photo-1531297484001-80022131f5a1'
    ]
  },
  {
    name: 'Fedora Magazine',
    url: 'https://fedoramagazine.org/feed/',
    category: 'linux',
    defaultImages: [
      'https://images.unsplash.com/photo-1629654297299-c8506221ca97',
      'https://images.unsplash.com/photo-1618477247222-acbdb0e159b3',
      'https://images.unsplash.com/photo-1555066931-4365d14bab8c',
      'https://images.unsplash.com/photo-1607799279861-4dd421887fb3',
      'https://images.unsplash.com/photo-1544256718-3bcf237f3974',
      'https://images.unsplash.com/photo-1537432376769-00f5c2f4c8d2',
      'https://images.unsplash.com/photo-1613677135043-a2512fbf49fa',
      'https://images.unsplash.com/photo-1531297484001-80022131f5a1',
      'https://images.unsplash.com/photo-1640552435388-a54879e72b28',
      'https://images.unsplash.com/photo-1515879218367-8466d910aaa4'
    ]
  },
  {
    name: 'OpenSUSE News',
    url: 'https://news.opensuse.org/feed.xml',
    category: 'linux',
    defaultImages: [
      'https://images.unsplash.com/photo-1629654297299-c8506221ca97',
      'https://images.unsplash.com/photo-1618477247222-acbdb0e159b3',
      'https://images.unsplash.com/photo-1555066931-4365d14bab8c',
      'https://images.unsplash.com/photo-1607799279861-4dd421887fb3',
      'https://images.unsplash.com/photo-1544256718-3bcf237f3974',
      'https://images.unsplash.com/photo-1537432376769-00f5c2f4c8d2',
      'https://images.unsplash.com/photo-1613677135043-a2512fbf49fa',
      'https://images.unsplash.com/photo-1531297484001-80022131f5a1',
      'https://images.unsplash.com/photo-1640552435388-a54879e72b28',
      'https://images.unsplash.com/photo-1515879218367-8466d910aaa4'
    ]
  },
  // Linux Kernel and Development
  {
    name: 'LWN.net',
    url: 'https://lwn.net/headlines/rss',
    category: 'linux',
    defaultImages: [
      'https://images.unsplash.com/photo-1629654297299-c8506221ca97',
      'https://images.unsplash.com/photo-1557853197-aefb550b6fdc',
      'https://images.unsplash.com/photo-1555066931-4365d14bab8c',
      'https://images.unsplash.com/photo-1607799279861-4dd421887fb3',
      'https://images.unsplash.com/photo-1544256718-3bcf237f3974',
      'https://images.unsplash.com/photo-1537432376769-00f5c2f4c8d2',
      'https://images.unsplash.com/photo-1613677135043-a2512fbf49fa',
      'https://images.unsplash.com/photo-1531297484001-80022131f5a1',
      'https://images.unsplash.com/photo-1640552435388-a54879e72b28',
      'https://images.unsplash.com/photo-1515879218367-8466d910aaa4'
    ]
  },
  {
    name: 'Phoronix',
    url: 'https://www.phoronix.com/rss.php',
    category: 'linux',
    defaultImages: [
      'https://images.unsplash.com/photo-1629654297299-c8506221ca97',
      'https://images.unsplash.com/photo-1557853197-aefb550b6fdc',
      'https://images.unsplash.com/photo-1555066931-4365d14bab8c',
      'https://images.unsplash.com/photo-1607799279861-4dd421887fb3',
      'https://images.unsplash.com/photo-1544256718-3bcf237f3974',
      'https://images.unsplash.com/photo-1537432376769-00f5c2f4c8d2',
      'https://images.unsplash.com/photo-1613677135043-a2512fbf49fa',
      'https://images.unsplash.com/photo-1531297484001-80022131f5a1',
      'https://images.unsplash.com/photo-1640552435388-a54879e72b28',
      'https://images.unsplash.com/photo-1515879218367-8466d910aaa4'
    ]
  },
  // Tech News
  {
    name: 'The Register',
    url: 'https://www.theregister.com/headlines.atom',
    category: 'tech',
    defaultImages: [
      'https://images.unsplash.com/photo-1518770660439-4636190af475',
      'https://images.unsplash.com/photo-1550751827-4bd374c3f58b',
      'https://images.unsplash.com/photo-1526374965328-7f61d4dc18c5',
      'https://images.unsplash.com/photo-1504639725590-34d0984388bd',
      'https://images.unsplash.com/photo-1544197150-b99a580bb7a8',
      'https://images.unsplash.com/photo-1563986768609-322da13575f3',
      'https://images.unsplash.com/photo-1451187580459-43490279c0fa',
      'https://images.unsplash.com/photo-1517433670267-08bbd4be890f',
      'https://images.unsplash.com/photo-1519389950473-47ba0277781c',
      'https://images.unsplash.com/photo-1523961131990-5ea7c61b2107'
    ]
  },
  {
    name: 'Ars Technica',
    url: 'https://feeds.arstechnica.com/arstechnica/index',
    category: 'tech',
    defaultImages: [
      'https://images.unsplash.com/photo-1518770660439-4636190af475',
      'https://images.unsplash.com/photo-1550751827-4bd374c3f58b',
      'https://images.unsplash.com/photo-1526374965328-7f61d4dc18c5',
      'https://images.unsplash.com/photo-1504639725590-34d0984388bd',
      'https://images.unsplash.com/photo-1544197150-b99a580bb7a8',
      'https://images.unsplash.com/photo-1563986768609-322da13575f3',
      'https://images.unsplash.com/photo-1451187580459-43490279c0fa',
      'https://images.unsplash.com/photo-1517433670267-08bbd4be890f',
      'https://images.unsplash.com/photo-1519389950473-47ba0277781c',
      'https://images.unsplash.com/photo-1523961131990-5ea7c61b2107'
    ]
  },
  {
    name: 'The New Stack',
    url: 'https://thenewstack.io/feed/',
    category: 'tech',
    defaultImages: [
      'https://images.unsplash.com/photo-1518770660439-4636190af475',
      'https://images.unsplash.com/photo-1550751827-4bd374c3f58b',
      'https://images.unsplash.com/photo-1526374965328-7f61d4dc18c5',
      'https://images.unsplash.com/photo-1504639725590-34d0984388bd',
      'https://images.unsplash.com/photo-1544197150-b99a580bb7a8',
      'https://images.unsplash.com/photo-1563986768609-322da13575f3',
      'https://images.unsplash.com/photo-1451187580459-43490279c0fa',
      'https://images.unsplash.com/photo-1517433670267-08bbd4be890f',
      'https://images.unsplash.com/photo-1519389950473-47ba0277781c',
      'https://images.unsplash.com/photo-1523961131990-5ea7c61b2107'
    ]
  },
  {
    name: 'Dev.to',
    url: 'https://dev.to/feed/',
    category: 'tech',
    defaultImages: [
      'https://images.unsplash.com/photo-1518770660439-4636190af475',
      'https://images.unsplash.com/photo-1550751827-4bd374c3f58b',
      'https://images.unsplash.com/photo-1526374965328-7f61d4dc18c5',
      'https://images.unsplash.com/photo-1504639725590-34d0984388bd',
      'https://images.unsplash.com/photo-1544197150-b99a580bb7a8',
      'https://images.unsplash.com/photo-1563986768609-322da13575f3',
      'https://images.unsplash.com/photo-1451187580459-43490279c0fa',
      'https://images.unsplash.com/photo-1517433670267-08bbd4be890f',
      'https://images.unsplash.com/photo-1519389950473-47ba0277781c',
      'https://images.unsplash.com/photo-1523961131990-5ea7c61b2107'
    ]
  }
];
