import React from 'react';
import { Terminal, Cpu, Activity, BarChart } from 'lucide-react';
import PageTitle from '../../components/PageTitle';

const SystemPerformance = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <PageTitle 
        title="System Performance Tuning"
        description="Comprehensive guide to optimizing Linux system performance"
        keywords="Linux performance, system tuning, optimization, kernel tuning"
      />

      <h1 className="text-3xl font-bold text-[#232323] mb-6">System Performance Tuning Guide</h1>

      <div className="prose prose-lg max-w-none">
        <div className="bg-white rounded-lg shadow-md p-6 mb-8">
          <h2 className="flex items-center text-2xl font-semibold mb-4">
            <Cpu className="w-6 h-6 text-[#EC6224] mr-2" />
            CPU Optimization
          </h2>
          
          <h3 className="text-xl font-semibold mt-6 mb-3">CPU Frequency Scaling</h3>
          <p>
            Modern CPUs support different frequency scaling governors to balance performance and power consumption.
            Here's how to configure them:
          </p>
          <pre className="bg-gray-900 text-white p-4 rounded-lg overflow-x-auto my-4">
            <code>{`# View available governors
cat /sys/devices/system/cpu/cpu0/cpufreq/scaling_available_governors

# Set performance governor
echo performance | sudo tee /sys/devices/system/cpu/cpu*/cpufreq/scaling_governor

# Make it persistent
sudo apt install cpufrequtils
sudo systemctl enable cpufrequtils`}</code>
          </pre>

          <h3 className="text-xl font-semibold mt-6 mb-3">Process Priority Management</h3>
          <p>
            Use nice and renice to adjust process priorities. The nice value ranges from -20 (highest priority)
            to 19 (lowest priority).
          </p>
          <pre className="bg-gray-900 text-white p-4 rounded-lg overflow-x-auto my-4">
            <code>{`# Start a process with custom priority
nice -n 10 ./my-process

# Adjust priority of running process
sudo renice -n -5 -p $(pgrep mysql)

# View process priorities
ps -el | grep mysql`}</code>
          </pre>

          <h3 className="text-xl font-semibold mt-6 mb-3">CPU Affinity</h3>
          <p>
            Bind processes to specific CPU cores for better performance and cache utilization:
          </p>
          <pre className="bg-gray-900 text-white p-4 rounded-lg overflow-x-auto my-4">
            <code>{`# View current CPU affinity
taskset -p $(pgrep mysql)

# Set CPU affinity (bind to cores 0 and 1)
taskset -pc 0,1 $(pgrep mysql)

# Start process with CPU affinity
taskset -c 0,1 ./my-process`}</code>
          </pre>
        </div>

        <div className="bg-white rounded-lg shadow-md p-6 mb-8">
          <h2 className="flex items-center text-2xl font-semibold mb-4">
            <Activity className="w-6 h-6 text-[#EC6224] mr-2" />
            Memory Management
          </h2>

          <h3 className="text-xl font-semibold mt-6 mb-3">Swap Configuration</h3>
          <p>
            Properly configured swap space is crucial for system stability. Here's how to optimize it:
          </p>
          <pre className="bg-gray-900 text-white p-4 rounded-lg overflow-x-auto my-4">
            <code>{`# Check current swappiness
cat /proc/sys/vm/swappiness

# Set swappiness temporarily
sudo sysctl vm.swappiness=10

# Make it persistent
echo 'vm.swappiness=10' | sudo tee -a /etc/sysctl.conf
sudo sysctl -p`}</code>
          </pre>

          <h3 className="text-xl font-semibold mt-6 mb-3">OOM Killer Configuration</h3>
          <p>
            Configure the Out-Of-Memory (OOM) killer to protect critical processes:
          </p>
          <pre className="bg-gray-900 text-white p-4 rounded-lg overflow-x-auto my-4">
            <code>{`# Protect process from OOM killer
echo -1000 > /proc/$(pgrep mysql)/oom_score_adj

# Make process more likely to be killed
echo 1000 > /proc/$(pgrep chrome)/oom_score_adj

# View current OOM scores
for proc in $(ls /proc/*/oom_score_adj); do
  printf "%s %s\\n" "$proc" "$(cat $proc)"
done`}</code>
          </pre>
        </div>

        <div className="bg-white rounded-lg shadow-md p-6 mb-8">
          <h2 className="flex items-center text-2xl font-semibold mb-4">
            <BarChart className="w-6 h-6 text-[#EC6224] mr-2" />
            I/O Optimization
          </h2>

          <h3 className="text-xl font-semibold mt-6 mb-3">I/O Schedulers</h3>
          <p>
            Choose the right I/O scheduler based on your workload:
          </p>
          <pre className="bg-gray-900 text-white p-4 rounded-lg overflow-x-auto my-4">
            <code>{`# View available schedulers
cat /sys/block/sda/queue/scheduler

# Set deadline scheduler
echo deadline > /sys/block/sda/queue/scheduler

# Make it persistent
echo 'ACTION=="add|change", KERNEL=="sd[a-z]", ATTR{queue/scheduler}="deadline"' | \
  sudo tee /etc/udev/rules.d/60-scheduler.rules`}</code>
          </pre>

          <h3 className="text-xl font-semibold mt-6 mb-3">File System Optimization</h3>
          <p>
            Optimize mount options for better performance:
          </p>
          <pre className="bg-gray-900 text-white p-4 rounded-lg overflow-x-auto my-4">
            <code>{`# Example /etc/fstab entry with optimized options
UUID=xxx /data ext4 defaults,noatime,nodiratime,commit=60 0 2

# Apply changes without reboot
sudo mount -o remount /data`}</code>
          </pre>
        </div>

        <div className="bg-white rounded-lg shadow-md p-6">
          <h2 className="flex items-center text-2xl font-semibold mb-4">
            <Terminal className="w-6 h-6 text-[#EC6224] mr-2" />
            Performance Monitoring
          </h2>

          <h3 className="text-xl font-semibold mt-6 mb-3">System Monitoring Tools</h3>
          <p>
            Essential commands for monitoring system performance:
          </p>
          <pre className="bg-gray-900 text-white p-4 rounded-lg overflow-x-auto my-4">
            <code>{`# CPU and memory usage
top -c
htop

# I/O statistics
iostat -xz 1

# Network statistics
sar -n DEV 1

# Memory usage
free -m
vmstat 1`}</code>
          </pre>

          <h3 className="text-xl font-semibold mt-6 mb-3">Performance Profiling</h3>
          <p>
            Use perf to identify performance bottlenecks:
          </p>
          <pre className="bg-gray-900 text-white p-4 rounded-lg overflow-x-auto my-4">
            <code>{`# Record system-wide performance data
sudo perf record -a -g sleep 60

# Analyze recorded data
sudo perf report

# Show real-time performance statistics
sudo perf top -p $(pgrep mysql)`}</code>
          </pre>

          <div className="mt-8 p-4 bg-blue-50 rounded-lg">
            <h4 className="text-lg font-semibold mb-2">Additional Resources</h4>
            <ul className="list-disc list-inside space-y-2">
              <li>
                <a 
                  href="https://www.kernel.org/doc/Documentation/sysctl/vm.txt"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#EC6224] hover:text-[#232323]"
                >
                  Linux Kernel VM Documentation
                </a>
              </li>
              <li>
                <a 
                  href="https://www.brendangregg.com/linuxperf.html"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#EC6224] hover:text-[#232323]"
                >
                  Linux Performance Analysis Tools
                </a>
              </li>
              <li>
                <a 
                  href="https://access.redhat.com/documentation/en-us/red_hat_enterprise_linux/8/html/monitoring_and_managing_system_status_and_performance/index"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#EC6224] hover:text-[#232323]"
                >
                  Red Hat Performance Monitoring Guide
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SystemPerformance;