import React from 'react';
import { Code, Globe, Layout, Database } from 'lucide-react';
import PageTitle from '../../components/PageTitle';

const WebDevelopment = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <PageTitle 
        title="Web Development"
        description="Modern web development practices and technologies"
        keywords="web development, frontend, backend, full stack"
      />

      <h1 className="text-3xl font-bold text-[#232323] mb-6">Web Development Guide</h1>

      <div className="prose prose-lg max-w-none">
        <div className="bg-white rounded-lg shadow-md p-6 mb-8">
          <h2 className="flex items-center text-2xl font-semibold mb-4">
            <Layout className="w-6 h-6 text-[#EC6224] mr-2" />
            Frontend Development
          </h2>

          <h3 className="text-xl font-semibold mt-6 mb-3">Modern Frontend Stack</h3>
          <ul className="list-disc pl-6 space-y-2">
            <li>React with TypeScript</li>
            <li>Next.js for SSR and Static Generation</li>
            <li>Tailwind CSS for Styling</li>
            <li>State Management with Redux Toolkit or Zustand</li>
          </ul>

          <h3 className="text-xl font-semibold mt-6 mb-3">Performance Optimization</h3>
          <pre className="bg-gray-900 text-white p-4 rounded-lg overflow-x-auto my-4">
            {`// Code splitting example
const DynamicComponent = dynamic(() => import('./Component'), {
  loading: () => <LoadingSpinner />,
  ssr: false
});

// Image optimization
<Image
  src="/hero.jpg"
  alt="Hero"
  width={1200}
  height={600}
  priority
  quality={85}
/>`}
          </pre>
        </div>

        <div className="bg-white rounded-lg shadow-md p-6 mb-8">
          <h2 className="flex items-center text-2xl font-semibold mb-4">
            <Database className="w-6 h-6 text-[#EC6224] mr-2" />
            Backend Development
          </h2>

          <h3 className="text-xl font-semibold mt-6 mb-3">API Development</h3>
          <pre className="bg-gray-900 text-white p-4 rounded-lg overflow-x-auto my-4">
            {`// Express.js API example
app.get('/api/data', async (req, res) => {
  try {
    const data = await getData();
    res.json(data);
  } catch (error) {
    res.status(500).json({ error: error.message });
  }
});

// GraphQL schema example
const typeDefs = gql\`
  type Query {
    users: [User!]!
    user(id: ID!): User
  }

  type User {
    id: ID!
    name: String!
    email: String!
  }
\`;`}
          </pre>
        </div>

        <div className="bg-white rounded-lg shadow-md p-6">
          <h2 className="flex items-center text-2xl font-semibold mb-4">
            <Globe className="w-6 h-6 text-[#EC6224] mr-2" />
            Full Stack Development
          </h2>

          <h3 className="text-xl font-semibold mt-6 mb-3">Modern Architecture</h3>
          <ul className="list-disc pl-6 space-y-2">
            <li>Microservices Architecture</li>
            <li>Containerization with Docker</li>
            <li>CI/CD Pipelines</li>
            <li>Cloud Deployment</li>
          </ul>

          <h3 className="text-xl font-semibold mt-6 mb-3">Security Best Practices</h3>
          <pre className="bg-gray-900 text-white p-4 rounded-lg overflow-x-auto my-4">
            {`// Authentication middleware
const authenticate = async (req, res, next) => {
  try {
    const token = req.headers.authorization?.split(' ')[1];
    if (!token) throw new Error('No token provided');
    
    const decoded = jwt.verify(token, process.env.JWT_SECRET);
    req.user = decoded;
    next();
  } catch (error) {
    res.status(401).json({ error: 'Unauthorized' });
  }
};

// CORS configuration
app.use(cors({
  origin: process.env.ALLOWED_ORIGINS.split(','),
  methods: ['GET', 'POST', 'PUT', 'DELETE'],
  credentials: true
}));`}
          </pre>

          <div className="mt-8 p-4 bg-blue-50 rounded-lg">
            <h4 className="text-lg font-semibold mb-2">Additional Resources</h4>
            <ul className="list-disc list-inside space-y-2">
              <li>
                <a 
                  href="https://nextjs.org/docs"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#EC6224] hover:text-[#232323]"
                >
                  Next.js Documentation
                </a>
              </li>
              <li>
                <a 
                  href="https://nodejs.org/en/docs"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#EC6224] hover:text-[#232323]"
                >
                  Node.js Documentation
                </a>
              </li>
              <li>
                <a 
                  href="https://www.typescriptlang.org/docs/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#EC6224] hover:text-[#232323]"
                >
                  TypeScript Documentation
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebDevelopment;