import React from 'react';
import { Database, Server, Code, Network } from 'lucide-react';
import PageTitle from '../../components/PageTitle';

const Backend = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <PageTitle 
        title="Backend Development"
        description="Modern backend development practices and architectures"
        keywords="backend development, Node.js, databases, APIs, microservices"
      />

      <h1 className="text-3xl font-bold text-[#232323] mb-6">Backend Development</h1>

      <div className="prose prose-lg max-w-none">
        <div className="bg-white rounded-lg shadow-md p-6 mb-8">
          <h2 className="flex items-center text-2xl font-semibold mb-4">
            <Code className="w-6 h-6 text-[#EC6224] mr-2" />
            Node.js Architecture
          </h2>
          
          <h3 className="text-xl font-semibold mt-6 mb-3">Express.js REST API</h3>
          <pre className="bg-gray-900 text-white p-4 rounded-lg overflow-x-auto my-4">
            {`import express from 'express';
import { Router } from 'express';
import { validateUser } from '../middleware/auth';

const router = Router();

router.get('/users', validateUser, async (req, res) => {
  try {
    const users = await User.find();
    res.json(users);
  } catch (error) {
    res.status(500).json({ error: error.message });
  }
});

export default router;`}
          </pre>

          <h3 className="text-xl font-semibold mt-6 mb-3">GraphQL API</h3>
          <pre className="bg-gray-900 text-white p-4 rounded-lg overflow-x-auto my-4">
            {`import { gql } from 'apollo-server-express';

const typeDefs = gql\`
  type User {
    id: ID!
    name: String!
    email: String!
    posts: [Post!]!
  }

  type Query {
    users: [User!]!
    user(id: ID!): User
  }
\`;

const resolvers = {
  Query: {
    users: () => User.find(),
    user: (_, { id }) => User.findById(id)
  }
};`}
          </pre>
        </div>

        <div className="bg-white rounded-lg shadow-md p-6 mb-8">
          <h2 className="flex items-center text-2xl font-semibold mb-4">
            <Database className="w-6 h-6 text-[#EC6224] mr-2" />
            Database Design
          </h2>

          <h3 className="text-xl font-semibold mt-6 mb-3">MongoDB Schema</h3>
          <pre className="bg-gray-900 text-white p-4 rounded-lg overflow-x-auto my-4">
            {`import mongoose from 'mongoose';

const userSchema = new mongoose.Schema({
  name: { 
    type: String, 
    required: true 
  },
  email: { 
    type: String, 
    required: true,
    unique: true
  },
  posts: [{
    type: mongoose.Schema.Types.ObjectId,
    ref: 'Post'
  }]
}, { 
  timestamps: true 
});

export const User = mongoose.model('User', userSchema);`}
          </pre>

          <h3 className="text-xl font-semibold mt-6 mb-3">PostgreSQL Schema</h3>
          <pre className="bg-gray-900 text-white p-4 rounded-lg overflow-x-auto my-4">
            {`CREATE TABLE users (
  id SERIAL PRIMARY KEY,
  name VARCHAR(255) NOT NULL,
  email VARCHAR(255) UNIQUE NOT NULL,
  created_at TIMESTAMP DEFAULT CURRENT_TIMESTAMP
);

CREATE TABLE posts (
  id SERIAL PRIMARY KEY,
  title VARCHAR(255) NOT NULL,
  content TEXT,
  user_id INTEGER REFERENCES users(id),
  created_at TIMESTAMP DEFAULT CURRENT_TIMESTAMP
);`}
          </pre>
        </div>

        <div className="bg-white rounded-lg shadow-md p-6 mb-8">
          <h2 className="flex items-center text-2xl font-semibold mb-4">
            <Server className="w-6 h-6 text-[#EC6224] mr-2" />
            Microservices
          </h2>

          <h3 className="text-xl font-semibold mt-6 mb-3">Service Communication</h3>
          <pre className="bg-gray-900 text-white p-4 rounded-lg overflow-x-auto my-4">
            {`// gRPC service definition
syntax = "proto3";

service UserService {
  rpc GetUser (GetUserRequest) returns (User) {}
  rpc CreateUser (CreateUserRequest) returns (User) {}
}

message User {
  string id = 1;
  string name = 2;
  string email = 3;
}

// Implementation
const server = new grpc.Server();
server.addService(userProto.UserService.service, {
  getUser: async (call, callback) => {
    const user = await User.findById(call.request.id);
    callback(null, user);
  }
});`}
          </pre>
        </div>

        <div className="bg-white rounded-lg shadow-md p-6">
          <h2 className="flex items-center text-2xl font-semibold mb-4">
            <Network className="w-6 h-6 text-[#EC6224] mr-2" />
            Message Queues
          </h2>

          <h3 className="text-xl font-semibold mt-6 mb-3">RabbitMQ Integration</h3>
          <pre className="bg-gray-900 text-white p-4 rounded-lg overflow-x-auto my-4">
            {`import amqp from 'amqplib';

// Publisher
const channel = await connection.createChannel();
await channel.assertQueue('task_queue', { durable: true });
channel.sendToQueue('task_queue', Buffer.from(JSON.stringify(data)), {
  persistent: true
});

// Consumer
channel.consume('task_queue', async (msg) => {
  const data = JSON.parse(msg.content);
  await processTask(data);
  channel.ack(msg);
}, { noAck: false });`}
          </pre>

          <div className="mt-8 p-4 bg-blue-50 rounded-lg">
            <h4 className="text-lg font-semibold mb-2">Additional Resources</h4>
            <ul className="list-disc list-inside space-y-2">
              <li>
                <a 
                  href="https://nodejs.org/api/documentation.html"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#EC6224] hover:text-[#232323]"
                >
                  Node.js Documentation
                </a>
              </li>
              <li>
                <a 
                  href="https://www.mongodb.com/docs/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#EC6224] hover:text-[#232323]"
                >
                  MongoDB Documentation
                </a>
              </li>
              <li>
                <a 
                  href="https://microservices.io/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#EC6224] hover:text-[#232323]"
                >
                  Microservices Patterns
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Backend;