import React from 'react';
import { Atom, Microscope, BookOpen, Lightbulb } from 'lucide-react';
import PageTitle from '../../components/PageTitle';

const QuantumResearch = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <PageTitle 
        title="Quantum Research"
        description="Latest breakthroughs in quantum computing research"
        keywords="quantum research, quantum breakthroughs, quantum science"
      />

      <div className="mb-8">
        <div className="flex items-center mb-4">
          <Atom className="w-8 h-8 text-[#EC6224] mr-3" />
          <h1 className="text-3xl font-bold text-[#232323]">Quantum Research</h1>
        </div>
        <p className="text-lg text-gray-600">
          Explore the latest breakthroughs and developments in quantum computing research.
        </p>
      </div>

      <div className="grid gap-8">
        <section className="bg-white rounded-lg shadow-md p-6">
          <div className="flex items-center mb-4">
            <Microscope className="w-6 h-6 text-[#EC6224] mr-3" />
            <h2 className="text-xl font-semibold">Current Research Areas</h2>
          </div>
          <div className="prose max-w-none">
            <ul className="space-y-4">
              <li>
              <a href="https://arxiv.org/abs/2202.08600" className="text-[#EC6224] hover:text-[#232323] font-medium" target="_new">
                <strong>Quantum Error Correction</strong>
                </a>
                <p>Latest developments in protecting quantum information from decoherence and errors.</p>
              </li>
              <li>
                <a href="https://arxiv.org/abs/1906.03860" className="text-[#EC6224] hover:text-[#232323] font-medium" target="_new">
                <strong>Quantum Supremacy</strong>
                </a>
                <p>Research into demonstrating quantum advantage in practical applications.</p>
              </li>
              <li>
                <a href="https://phys.org/news/2022-11-scalable-quantum-memory-lifetime-seconds.html" className="text-[#EC6224] hover:text-[#232323] font-medium" target="_new">
                <strong>Quantum Memory</strong>
                </a>
                <p>Advancements in storing and maintaining quantum states.</p>
              </li>
            </ul>
          </div>
        </section>

        <section className="bg-white rounded-lg shadow-md p-6">
          <div className="flex items-center mb-4">
            <BookOpen className="w-6 h-6 text-[#EC6224] mr-3" />
            <h2 className="text-xl font-semibold">Recent Publications</h2>
          </div>
          <div className="prose max-w-none">
            <ul className="space-y-4">
              <li>
                <a href="https://www.nature.com/articles/s41567-024-02621-x" className="text-[#EC6224] hover:text-[#232323] font-medium" target="_new">
                  Advances in Quantum Error Correction Codes
                </a>
                <p>Published in Nature Quantum Information, 2024</p>
              </li>
              <li>
                <a href="https://journals.aps.org/prl/abstract/10.1103/PhysRevLett.132.150604" className="text-[#EC6224] hover:text-[#232323] font-medium" target="_new">
                  Quantum Memory Breakthrough Using Rare-Earth Ions
                </a>
                <p>Published in Physical Review Letters, 2024</p>
              </li>
              <li>
                <a href="https://www.science.org/doi/pdf/10.1126/sciadv.adg6685" className="text-[#EC6224] hover:text-[#232323] font-medium" target="_new">
                  Novel Approaches to Quantum Gate Implementation
                </a>
                <p>Published in Science, 2024</p>
              </li>
            </ul>
          </div>
        </section>

        <section className="bg-white rounded-lg shadow-md p-6">
          <div className="flex items-center mb-4">
            <Lightbulb className="w-6 h-6 text-[#EC6224] mr-3" />
            <h2 className="text-xl font-semibold">Future Directions</h2>
          </div>
          <div className="prose max-w-none">
            <ul className="space-y-4">
              <li>
              <a href="https://news.mit.edu/2024/modular-scalable-hardware-architecture-quantum-computer-0529" className="text-[#EC6224] hover:text-[#232323] font-medium" target="_new">
                <strong>Scalable Quantum Systems</strong>
                </a>
                <p>Research into building larger, more stable quantum computers.</p>
              </li>
              <li>
              <a href="https://epjquantumtechnology.springeropen.com/articles/10.1140/epjqt/s40507-023-00192-z" className="text-[#EC6224] hover:text-[#232323] font-medium" target="_new">
                <strong>Quantum-Classical Interfaces</strong>
                </a>
                <p>Improving the connection between quantum and classical computing systems.</p>
              </li>
              <li>
              <a href="https://news.mit.edu/2023/new-qubit-circuit-enables-quantum-operations-higher-accuracy-0925" className="text-[#EC6224] hover:text-[#232323] font-medium" target="_new">
                <strong>Novel Qubit Technologies</strong>
                </a>
                <p>Exploring new materials and methods for qubit implementation.</p>
              </li>
            </ul>
          </div>
        </section>
      </div>
    </div>
  );
};

export default QuantumResearch;
