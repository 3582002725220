import React from 'react';
import { Link } from 'react-router-dom';
import { Github, Mail } from 'lucide-react';
import { X } from './Icons';

const Footer = () => {
  return (
    <footer className="bg-[#232323] text-white py-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="mb-4 md:mb-0">
            <Link to="/" className="text-[#EC6224] font-bold text-xl">QuantumBytz</Link>
          </div>
          <div className="flex space-x-6">
            <Link to="/about" className="hover:text-[#EC6224]">About</Link>
            <Link to="/quantum-news" className="hover:text-[#EC6224]">News</Link>
            <Link to="/tech-stack" className="hover:text-[#EC6224]">Tech</Link>
          </div>
        </div>
        <div className="mt-6 flex justify-center space-x-6">
          <a href="https://github.com/yodabytz" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white">
            <Github className="w-6 h-6" />
          </a>
          <a href="https://x.com/quantumbytz" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white">
            <X className="w-6 h-6" />
          </a>
          <a href="mailto:info@quantumbytz.com" className="text-gray-400 hover:text-white">
            <Mail className="w-6 h-6" />
          </a>
        </div>
        <div className="mt-4 text-center text-gray-400 text-sm">
          © {new Date().getFullYear()} QuantumBytz. All rights reserved.
        </div>
      </div>
    </footer>
  );
};

export default Footer;