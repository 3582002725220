export function extractImageFromHTML(html: string): string | null {
  const imgRegex = /<img[^>]+src=["']([^"']+)["'][^>]*>/i;
  const imgMatch = html.match(imgRegex);
  if (imgMatch && imgMatch[1]) {
    const url = imgMatch[1];
    if (url.match(/^https?:\/\/.*\.(jpg|jpeg|png|gif|webp)/i)) {
      return url;
    }
  }
  return null;
}

export function getRandomImage(category: string, defaultImages: string[]): string {
  if (!defaultImages.length) {
    return 'https://images.unsplash.com/photo-1635070041078-e363dbe005cb';
  }
  return defaultImages[Math.floor(Math.random() * defaultImages.length)];
}